import React from 'react';

const SVGSearchIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' id='search' width='13.997' height='13.997' viewBox='0 0 13.997 13.997'>
        <g id='Group_2' data-name='Group 2'>
            <g id='Group_1' data-name='Group 1'>
                <path
                    id='Path_1'
                    data-name='Path 1'
                    d='M6.164 0a6.164 6.164 0 1 0 6.164 6.164A6.171 6.171 0 0 0 6.164 0zm0 11.19a5.026 5.026 0 1 1 5.026-5.026 5.032 5.032 0 0 1-5.026 5.026z'
                />
            </g>
        </g>
        <g id='Group_4' data-name='Group 4' transform='translate(9.597 9.597)'>
            <g id='Group_3' data-name='Group 3'>
                <path
                    id='Path_2'
                    data-name='Path 2'
                    d='m355.279 354.475-3.262-3.262a.569.569 0 0 0-.8.8l3.262 3.262a.569.569 0 0 0 .8-.8z'
                    transform='translate(-351.046 -351.046)'
                />
            </g>
        </g>
    </svg>
);

export default SVGSearchIcon;
