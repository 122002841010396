export default {
    CONFIRM: '确定',
    OOPS_COMMA: '糟糕…',
    SOMETHING_WENT_WRONG_ACCENT: '出错了',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: '你找的页面不见了。',
    BACK_TO_HOME: '返回首页',
    PICK_A_TIM: '选择时间',
    TODAY: '今天',
    TOMORROW: '明天',
    SELECT_TIME: '选择时间',
    TEXT_24HR: '24 小时',
    SCHEDULE_CALL: '预约电话',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: '抖音',
    INSTAGRAM: 'Instagram',
    LINKEDIN: '领英',
    TWITTER: 'Twitter',
    CALL: '通话',
    EMAIL: '邮箱',
    WEBSITE: '网站',
    OPENING_HOURS: '工作时间',
    BC_ABOUT_TITLE: '关于',
    BC_ADDRESS_TITLE: '地址',
    BC_NAVIGATE_BTN_TEXT: '导航',
    BC_WEBSITE_TITLE: '网站',
    BC_CONTACT_US_TITLE: '联系我们',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: '谢谢！',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: '发送成功',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: '无效姓名',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: '你的名字',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: '无效的电话号码',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: '电话号码',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: '编辑短信息...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: '发送',
    BC_FOLLOW_US_TITLE: '关注我们',
    BC_SHARE_WITH_FRIENDS_TITLE: '推荐给好友使用',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: '保存到地址簿',
    FOOTER_CREATE_BC_PROMO_TEXT: '创建名片',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: '预约电话',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: '回电时间：',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: '确定',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: '选择时间',
    SCHEDULE_TIME_ASAP_TEXT: '尽快',
    SCHEDULE_TIME_30MIN_TEXT: '30分钟内',
    SCHEDULE_TIME_1H_TEXT: '1小时内',
    PICK_PAGE_PICK_A_TIME_TITLE: '选择时间',
    PICK_PAGE_SELECT_WHEN_POINT: '选择时间：',
    PICK_PAGE_TODAY_BTN_TEXT: '今天',
    PICK_PAGE_TOMORROW_BTN_TEXT: '明天',
    PICK_PAGE_CONTINUE_BTN_TEXT: '继续',
    TIME_LIST_IN_15_MIN: '15分钟内',
    TIME_LIST_IN_30_MIN: '30分钟内',
    TIME_LIST_IN_1_H: '1小时内',
    TIME_LIST_AT_TIME: '在{{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: '在{{hours}}:00{{amPm}}',
    IDENT_PAGE_TODAY_TEXT: '今天',
    IDENT_PAGE_TOMORROW_TEXT: '明天',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}}时间：{{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: '无效姓名',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: '全名',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: '无效的电话号码',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: '电话号码',
    CONFIRMED_PAGE_CONFIRMED_TEXT: '谢谢！',
    CONFIRMED_PAGE_DESCRIPTION: '成功约定以下时间点的电话：{{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: '业务电话太多？',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: '使用Leader管理潜在客户和更多地获客',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: '免费试用',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: '确定',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: '继续',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: '查找国家',
    SCHEDULE_PAGE_SUBTITLE_TEXT: '让我们约个时间谈谈。请告知我一个可以联系你的合适的时间。',
    HERE_IS_MY_BC_LINK: '下面是我的名片链接：{{link}}',
    OPENING_HOURS_MON: '周一',
    OPENING_HOURS_TUE: '周二',
    OPENING_HOURS_WED: '周三',
    OPENING_HOURS_THU: '周四',
    OPENING_HOURS_FRI: '周五',
    OPENING_HOURS_SAT: '周六',
    OPENING_HOURS_SUN: '周日',
};
