const getUserInfoEndpoint = (callbackId, isBusinessCard) =>
    `${process.env.REACT_APP_BASE_API_URL}${callbackId}${isBusinessCard ? '?source=bc' : ''}`;

const getGetBusinessCardEndpoint = (callbackId) => `${process.env.REACT_APP_BASE_CARD_API_URL}${callbackId}`;

const getPutMessageEndpoint = (slug) => `${process.env.REACT_APP_BASE_CARD_API_URL}${slug}/message`;

const getGetVCardEndpoint = (slug) => `${process.env.REACT_APP_BASE_CARD_API_URL}${slug}/vcard`;

export {getUserInfoEndpoint, getGetBusinessCardEndpoint, getPutMessageEndpoint, getGetVCardEndpoint};
