import {useEffect} from 'react';

import {setIsWebview} from '../store/reducers/global';
import {dispatch} from '../store/store';

const useWebviewCheck = () => {
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const {webview} = Object.fromEntries(urlSearchParams.entries());

        dispatch(setIsWebview(!!webview));
    }, []);
};

export default useWebviewCheck;
