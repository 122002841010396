const languageCode = {
    EN: 'en',
    AR: 'ar',
    ZH_CN: 'zh_CN',
    ZH_TW: 'zh_TW',
    NL: 'nl',
    FR: 'fr',
    DE: 'de',
    HE: 'he',
    HI: 'hi',
    IT: 'it',
    JA: 'ja',
    KO: 'ko',
    FA: 'fa',
    PT: 'pt',
    PT_BR: 'pt_BR',
    UK: 'uk',
    ES: 'es',
    TH: 'th',
    TR: 'tr',
    RU: 'ru',
};

export default languageCode;
