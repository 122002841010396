export default {
    CONFIRM: 'Подтвердить',
    OOPS_COMMA: 'Ой',
    SOMETHING_WENT_WRONG_ACCENT: 'Что-то пошло не так',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'Страница, которую вы ищете, больше не существует.',
    BACK_TO_HOME: 'Вернуться на главную страницу',
    PICK_A_TIM: 'Выбрать время',
    TODAY: 'Cегодня',
    TOMORROW: 'Завтра',
    SELECT_TIME: 'Выбрать время',
    TEXT_24HR: '24 часа',
    SCHEDULE_CALL: 'Запланировать звонок',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Позвонить',
    EMAIL: 'Email',
    WEBSITE: 'Веб-сайт',
    OPENING_HOURS: 'Часы работы',
    BC_ABOUT_TITLE: 'Информация',
    BC_ADDRESS_TITLE: 'Адрес',
    BC_NAVIGATE_BTN_TEXT: 'Навигация',
    BC_WEBSITE_TITLE: 'Веб-сайт',
    BC_CONTACT_US_TITLE: 'Связаться с нами',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Спасибо!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Ваше сообщение успешно отправлено',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Неверное имя',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'Ваше имя',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Недействительный номер',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Номер телефона',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Написать сообщение',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Отправить',
    BC_FOLLOW_US_TITLE: 'Подписывайтесь на нас',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Поделиться с друзьями',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Сохранить в адресной книге',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Создайте свою визитку',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Запланировать звонок',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Когда перезвонить:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Подтвердить',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Выбрать время',
    SCHEDULE_TIME_ASAP_TEXT: 'В ближайшее время',
    SCHEDULE_TIME_30MIN_TEXT: 'Через 30 мин.',
    SCHEDULE_TIME_1H_TEXT: 'Через 1 час',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Выбрать время',
    PICK_PAGE_SELECT_WHEN_POINT: 'Выбрать',
    PICK_PAGE_TODAY_BTN_TEXT: 'Cегодня',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Завтра',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Продолжить',
    TIME_LIST_IN_15_MIN: 'Через 15 мин.',
    TIME_LIST_IN_30_MIN: 'Через 30 мин.',
    TIME_LIST_IN_1_H: 'Через 1 час',
    TIME_LIST_AT_TIME: 'В {{часов}}:00',
    TIME_LIST_AT_TIME_AMPM: 'В {{часов}}:00',
    IDENT_PAGE_TODAY_TEXT: 'Cегодня',
    IDENT_PAGE_TOMORROW_TEXT: 'Завтра',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{день}} в {{время}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Неверное имя',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Полное имя',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Недействительный номер телефона',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Номер телефона',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Спасибо!',
    CONFIRMED_PAGE_DESCRIPTION: 'Звонок был успешно запланирован на {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'Слишком много деловых звонков?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Управляйте лидами и привлекайте новых клиентов с помощью Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Попробовать бесплатно',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Подтвердить',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Продолжить',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Выбрать страну',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'Давайте пообщаемся. Когда с вами лучше связаться?',
    HERE_IS_MY_BC_LINK: 'Вот ссылка на мою визитную карточку: {{link}}',
    OPENING_HOURS_MON: 'Пн',
    OPENING_HOURS_TUE: 'Вт',
    OPENING_HOURS_WED: 'Ср',
    OPENING_HOURS_THU: 'Чт',
    OPENING_HOURS_FRI: 'Пт',
    OPENING_HOURS_SAT: 'Сб',
    OPENING_HOURS_SUN: 'Вс',
};
