import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Error404 from '../../components/Error404';
import routePaths from '../../consts/route/routePaths';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

const CardPageNew = lazy(() => import('../../pages/CardPage'));
const ScheduleCallbackPage = lazy(() => import('../../pages/ScheduleCallbackPage'));
const ScheduleCallbackIdentPage = lazy(() => import('../../pages/ScheduleCallbackIdentPage'));
const ScheduleCallbackPickPage = lazy(() => import('../../pages/ScheduleCallbackPickPage'));
const ScheduleConfirmedPage = lazy(() => import('../../pages/ScheduleConfirmedPage'));

const Router = ({layout, data}) => {
    const isBC = !window.location.pathname.includes('assistant');

    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path={routePaths.BC_CARD_PAGE}
                    render={() => (
                        <Suspense fallback={''}>
                            <CardPageNew data={data} layout={layout} isBC={isBC} />
                            <LanguageSelect layout={layout} isForMobile />
                        </Suspense>
                    )}
                />
                <Route
                    exact
                    path={[routePaths.BC_MAIN_PAGE, routePaths.ASSISTANT_MAIN_PAGE]}
                    render={() => (
                        <Suspense fallback={''}>
                            <ScheduleCallbackPage layout={layout} data={data} isBC={isBC} />
                            <LanguageSelect layout={layout} isForMobile />
                        </Suspense>
                    )}
                />
                <Route
                    exact
                    path={[routePaths.BC_IDENT_PAGE, routePaths.ASSISTANT_IDENT_PAGE]}
                    render={() => (
                        <Suspense fallback={''}>
                            <ScheduleCallbackIdentPage layout={layout} data={data} isBC={isBC} />
                            <LanguageSelect layout={layout} isForMobile />
                        </Suspense>
                    )}
                />
                <Route
                    exact
                    path={[routePaths.BC_PICK_TIME_PAGE, routePaths.ASSISTANT_PICK_PAGE]}
                    render={() => (
                        <Suspense fallback={''}>
                            <ScheduleCallbackPickPage layout={layout} data={data} isBC={isBC} />
                            <LanguageSelect layout={layout} isForMobile />
                        </Suspense>
                    )}
                />
                <Route
                    exact
                    path={[routePaths.BC_CONFIRM_PAGE, routePaths.ASSISTANT_CONFIRM_PAGE]}
                    render={() => (
                        <Suspense fallback={''}>
                            <ScheduleConfirmedPage layout={layout} data={data} isBC={isBC} />
                            <LanguageSelect layout={layout} isForMobile />
                        </Suspense>
                    )}
                />
                <Route exact path={routePaths.ERROR_404} component={Error404} />
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
