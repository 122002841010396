export default {
    CONFIRM: 'Confirmar',
    OOPS_COMMA: 'Oops,',
    SOMETHING_WENT_WRONG_ACCENT: 'Algo correu mal.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'A página que procura já não existe.',
    BACK_TO_HOME: 'Voltar ao início',
    PICK_A_TIM: 'Escolha uma hora',
    TODAY: 'Hoje',
    TOMORROW: 'Amanhã',
    SELECT_TIME: 'Selecione uma hora',
    TEXT_24HR: '24 h',
    SCHEDULE_CALL: 'Agendar chamada',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Ligar',
    EMAIL: 'E-mail',
    WEBSITE: 'Website',
    OPENING_HOURS: 'Horário de abertura',
    BC_ABOUT_TITLE: 'Sobre',
    BC_ADDRESS_TITLE: 'Endereço',
    BC_NAVIGATE_BTN_TEXT: 'Navegar',
    BC_WEBSITE_TITLE: 'Website',
    BC_CONTACT_US_TITLE: 'Contacte-nos',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Obrigado!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'A sua mensagem foi enviada com sucesso',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Nome inválido',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'O seu nome',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Número inválido',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Número de telefone',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Escrever uma mensagem...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Enviar',
    BC_FOLLOW_US_TITLE: 'Siga-nos',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Partilhar com amigos',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Guardar nos contactos',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Crie o seu próprio cartão de visita',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Agendar uma chamada',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Quando ligar de volta:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Confirmar',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Escolha uma hora',
    SCHEDULE_TIME_ASAP_TEXT: 'ASAP',
    SCHEDULE_TIME_30MIN_TEXT: 'em 30 min',
    SCHEDULE_TIME_1H_TEXT: 'em 1 hora',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Escolha uma hora',
    PICK_PAGE_SELECT_WHEN_POINT: 'Selecionar quando:',
    PICK_PAGE_TODAY_BTN_TEXT: 'Hoje',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Amanhã',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Continuar',
    TIME_LIST_IN_15_MIN: 'Em 15 min',
    TIME_LIST_IN_30_MIN: 'Em 30 min',
    TIME_LIST_IN_1_H: 'Em 1 hora',
    TIME_LIST_AT_TIME: 'Às {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'Às {{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'Hoje',
    IDENT_PAGE_TOMORROW_TEXT: 'Amanhã',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} às {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Nome inválido',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Nome completo',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Número de telefone inválido',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Número de telefone',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Obrigado!',
    CONFIRMED_PAGE_DESCRIPTION: 'A chamada foi agendada com sucesso para {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'Tem muitas chamadas de negócios?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Faça a gestão dos leads e ganhe mais clientes com o Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Experimente gratuitamente',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Confirmado',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Continuar',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Pesquisar país',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'Vamos falar. Por favor, diga-me quando é a melhor altura para entrar em contacto:',
    HERE_IS_MY_BC_LINK: 'Aqui está o link para o meu cartão de visita: {{link}}',
    OPENING_HOURS_MON: 'Seg',
    OPENING_HOURS_TUE: 'Ter',
    OPENING_HOURS_WED: 'Qua',
    OPENING_HOURS_THU: 'Qui',
    OPENING_HOURS_FRI: 'Sex',
    OPENING_HOURS_SAT: 'Sáb',
    OPENING_HOURS_SUN: 'Dom',
};
