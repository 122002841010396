import arFlag from '../../assets/images/business-card/flags/ar.png';
import deFlag from '../../assets/images/business-card/flags/de.png';
import enFlag from '../../assets/images/business-card/flags/en.png';
import esFlag from '../../assets/images/business-card/flags/es.png';
import faFlag from '../../assets/images/business-card/flags/fa.png';
import frFlag from '../../assets/images/business-card/flags/fr.png';
import heFlag from '../../assets/images/business-card/flags/he.png';
import hiFlag from '../../assets/images/business-card/flags/hi.png';
import itFlag from '../../assets/images/business-card/flags/it.png';
import jaFlag from '../../assets/images/business-card/flags/ja.png';
import koFlag from '../../assets/images/business-card/flags/ko.png';
import nlFlag from '../../assets/images/business-card/flags/nl.png';
import ptFlag from '../../assets/images/business-card/flags/pt.png';
import pt_BRFlag from '../../assets/images/business-card/flags/pt_BR.png';
import ruFlag from '../../assets/images/business-card/flags/ru.png';
import thFlag from '../../assets/images/business-card/flags/th.png';
import trFlag from '../../assets/images/business-card/flags/tr.png';
import ukFlag from '../../assets/images/business-card/flags/uk.png';
import zh_CNFlag from '../../assets/images/business-card/flags/zh_CN.png';
import zh_TWFlag from '../../assets/images/business-card/flags/zh_TW.png';
import languageCode from '../../consts/localization/languageCode';
import localizationKeys from '../../consts/localization/localizationKeys';

const getLanguageData = async (code) => {
    //TODO: Remove when will available
    if (code === languageCode.PT) {
        return null;
    }

    const flag = (() => {
        switch (code) {
            case languageCode.AR:
                return arFlag;
            case languageCode.DE:
                return deFlag;
            case languageCode.EN:
                return enFlag;
            case languageCode.ES:
                return esFlag;
            case languageCode.FA:
                return faFlag;
            case languageCode.FR:
                return frFlag;
            case languageCode.HE:
                return heFlag;
            case languageCode.HI:
                return hiFlag;
            case languageCode.IT:
                return itFlag;
            case languageCode.JA:
                return jaFlag;
            case languageCode.KO:
                return koFlag;
            case languageCode.NL:
                return nlFlag;
            case languageCode.PT:
                return ptFlag;
            case languageCode.PT_BR:
                return pt_BRFlag;
            case languageCode.RU:
                return ruFlag;
            case languageCode.TH:
                return thFlag;
            case languageCode.TR:
                return trFlag;
            case languageCode.UK:
                return ukFlag;
            case languageCode.ZH_CN:
                return zh_CNFlag;
            case languageCode.ZH_TW:
                return zh_TWFlag;
        }
    })();

    return {
        flag,
        code,
        name: localizationKeys[`LNG_NAME_${code.toUpperCase()}`],
    };
};

export const getLanguageSelectData = async () => {
    const data = [];

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(languageCode)) {
        const langData = await getLanguageData(value);

        if (langData) {
            data.push(langData);
        }
    }

    return [...new Set(data)];
};
