export default {
    CONFIRM: '확인',
    OOPS_COMMA: '이런,',
    SOMETHING_WENT_WRONG_ACCENT: '문제가 발생했습니다.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: '찾고 있는 페이지가 더 이상 존재하지 않습니다.',
    BACK_TO_HOME: '홈으로 돌아가기',
    PICK_A_TIM: '시간 선택하기',
    TODAY: '오늘',
    TOMORROW: '내일',
    SELECT_TIME: '시간 선택하기',
    TEXT_24HR: '24시간',
    SCHEDULE_CALL: '통화 예약',
    WHATSAPP: '왓츠앱',
    FACEBOOK: '페이스북',
    TIKTOK: '틱톡',
    INSTAGRAM: '인스타그램',
    LINKEDIN: '링크드인',
    TWITTER: '트위터',
    CALL: '전화',
    EMAIL: '이메일',
    WEBSITE: '웹사이트',
    OPENING_HOURS: '운영 시간',
    BC_ABOUT_TITLE: '소개',
    BC_ADDRESS_TITLE: '주소',
    BC_NAVIGATE_BTN_TEXT: '탐색',
    BC_WEBSITE_TITLE: '웹사이트',
    BC_CONTACT_US_TITLE: '문의하기',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: '감사합니다!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: '메시지가 성공적으로 전송되었습니다.',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: '유효하지 않은 이름입니다',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: '당신의 이름',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: '유효하지 않은 번호입니다',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: '전화 번호',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: '메세지 작성하기...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: '전송',
    BC_FOLLOW_US_TITLE: '팔로우하기',
    BC_SHARE_WITH_FRIENDS_TITLE: '친구들과 공유하기',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: '주소록에 저장하기',
    FOOTER_CREATE_BC_PROMO_TEXT: '나만의 명함 생성하기',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: '통화 예약',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: '다시 전화하는 시간:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: '확인',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: '시간 설정하기',
    SCHEDULE_TIME_ASAP_TEXT: '최대한 빨리',
    SCHEDULE_TIME_30MIN_TEXT: '30분 내',
    SCHEDULE_TIME_1H_TEXT: '1시간 내',
    PICK_PAGE_PICK_A_TIME_TITLE: '시간 선택하기',
    PICK_PAGE_SELECT_WHEN_POINT: '시간 선택하기:',
    PICK_PAGE_TODAY_BTN_TEXT: '오늘',
    PICK_PAGE_TOMORROW_BTN_TEXT: '내일',
    PICK_PAGE_CONTINUE_BTN_TEXT: '계속하기',
    TIME_LIST_IN_15_MIN: '15분 내',
    TIME_LIST_IN_30_MIN: '30분 내',
    TIME_LIST_IN_1_H: '1시간 내',
    TIME_LIST_AT_TIME: '{{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: '{{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: '오늘',
    IDENT_PAGE_TOMORROW_TEXT: '내일',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}}의 {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: '유효하지 않은 이름입니다',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: '성명',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: '유효하지 않은 전화 번호입니다.',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: '전화 번호',
    CONFIRMED_PAGE_CONFIRMED_TEXT: '감사합니다!',
    CONFIRMED_PAGE_DESCRIPTION: '{{selectedTime}}에 성공적으로 통화가 예약되었습니다.',
    CONFIRMED_PAGE_FOOTER_TITLE: '업무상 통화가 많습니까?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Leader로 리드 관리와 더 많은 고객을 확보해 보십시오',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: '무료로 사용해 보십시오.',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: '확인됨',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: '계속하기',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: '국가 검색',
    SCHEDULE_PAGE_SUBTITLE_TEXT: '연락하기 가장 좋은 시간이 언제인지 알려주십시오.',
    HERE_IS_MY_BC_LINK: '다음은 내 명함 링크입니다: {{link}}',
    OPENING_HOURS_MON: '월',
    OPENING_HOURS_TUE: '화',
    OPENING_HOURS_WED: '수',
    OPENING_HOURS_THU: '목',
    OPENING_HOURS_FRI: '금',
    OPENING_HOURS_SAT: '토',
    OPENING_HOURS_SUN: '일',
};
