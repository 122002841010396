const localizationKeys = {
    CALL_ASSISTANT: 'CALL_ASSISTANT',
    CONFIRM: 'CONFIRM',
    OOPS_COMMA: 'OOPS_COMMA',
    SOMETHING_WENT_WRONG_ACCENT: 'SOMETHING_WENT_WRONG_ACCENT',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'PAGE_YOU_LOOK_NO_LONGER_EXISTS',
    BACK_TO_HOME: 'BACK_TO_HOME',
    SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
    CALL_ME_ASAP: 'CALL_ME_ASAP',
    CALL_ME_IN_30_MIN: 'CALL_ME_IN_30_MIN',
    CALL_ME_IN_1_HOUR: 'CALL_ME_IN_1_HOUR',
    WHEN_TO_CALL_BACK: 'WHEN_TO_CALL_BACK',
    PICK_A_TIME: 'PICK_A_TIME',
    TODAY: 'TODAY',
    TOMORROW: 'TOMORROW',
    SELECT_WHEN: 'SELECT_WHEN',
    SELECT_TIME: 'SELECT_TIME',
    TEXT_24HR: 'TEXT_24HR',
    TRY_FOR_FREE: 'TRY_FOR_FREE',
    NO_CONNECT: 'NO_CONNECT',
    OOPS_SOMETHING_WENT_WRONG_DOT: 'OOPS_SOMETHING_WENT_WRONG_DOT',
    PLEASE_TRY_AGAIN_LATER: 'PLEASE_TRY_AGAIN_LATER',
    CLOSE: 'CLOSE',
    SCHEDULE_A_CALL: 'SCHEDULE_A_CALL',
    SCHEDULE_CALL: 'SCHEDULE_CALL',
    CONTINUE: 'CONTINUE',
    WHATSAPP: 'WHATSAPP',
    FACEBOOK: 'FACEBOOK',
    TIKTOK: 'TIKTOK',
    INSTAGRAM: 'INSTAGRAM',
    LINKEDIN: 'LINKEDIN',
    TWITTER: 'TWITTER',
    CALL: 'CALL',
    EMAIL: 'EMAIL',
    WEBSITE: 'WEBSITE',
    YOUTUBE: 'YOUTUBE',
    OPENING_HOURS: 'OPENING_HOURS',
    BC_ABOUT_TITLE: 'BC_ABOUT_TITLE',
    BC_ADDRESS_TITLE: 'BC_ADDRESS_TITLE',
    BC_NAVIGATE_BTN_TEXT: 'BC_NAVIGATE_BTN_TEXT',
    BC_WEBSITE_TITLE: 'BC_WEBSITE_TITLE',
    BC_CONTACT_US_TITLE: 'BC_CONTACT_US_TITLE',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'BC_CONTACT_US_MESSAGE_SENT_TITLE',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'BC_CONTACT_US_INPUT_NAME_PLACEHOLDER',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'BC_CONTACT_US_INPUT_TEL_PLACEHOLDER',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'BC_CONTACT_US_COMMENT_PLACEHOLDER',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'BC_CONTACT_US_BUTTON_SEND_TEXT',
    BC_FOLLOW_US_TITLE: 'BC_FOLLOW_US_TITLE',
    BC_SHARE_WITH_FRIENDS_TITLE: 'BC_SHARE_WITH_FRIENDS_TITLE',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'BC_SAVE_TO_ADDRESS_BOOK_TITLE',
    FOOTER_CREATE_BC_PROMO_TEXT: 'FOOTER_CREATE_BC_PROMO_TEXT',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'SCHEDULE_CALL_WHEN_CALL_BACK_TITLE',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'SCHEDULE_CALL_CONFIRM_BUTTON_TEXT',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'SCHEDULE_CALL_PICK_BUTTON_TEXT',
    SCHEDULE_TIME_ASAP_TEXT: 'SCHEDULE_TIME_ASAP_TEXT',
    SCHEDULE_TIME_30MIN_TEXT: 'SCHEDULE_TIME_30MIN_TEXT',
    SCHEDULE_TIME_1H_TEXT: 'SCHEDULE_TIME_1H_TEXT',
    PICK_PAGE_PICK_A_TIME_TITLE: 'PICK_PAGE_PICK_A_TIME_TITLE',
    PICK_PAGE_SELECT_WHEN_POINT: 'PICK_PAGE_SELECT_WHEN_POINT',
    PICK_PAGE_TODAY_BTN_TEXT: 'PICK_PAGE_TODAY_BTN_TEXT',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'PICK_PAGE_TOMORROW_BTN_TEXT',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'PICK_PAGE_CONTINUE_BTN_TEXT',
    TIME_LIST_IN_15_MIN: 'TIME_LIST_IN_15_MIN',
    TIME_LIST_IN_30_MIN: 'TIME_LIST_IN_30_MIN',
    TIME_LIST_IN_1_H: 'TIME_LIST_IN_1_H',
    TIME_LIST_AT_TIME: 'TIME_LIST_AT_TIME',
    TIME_LIST_AT_TIME_AMPM: 'TIME_LIST_AT_TIME_AMPM',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: 'IDENT_PAGE_SELECTED_TIME_CARD_TEXT',
    IDENT_PAGE_TODAY_TEXT: 'IDENT_PAGE_TODAY_TEXT',
    IDENT_PAGE_TOMORROW_TEXT: 'IDENT_PAGE_TOMORROW_TEXT',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'IDENT_PAGE_NAME_INPUT_LABEL_TEXT',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'IDENT_PAGE_NUMBER_ERROR_MESSAGE',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'IDENT_PAGE_SCHEDULE_BTN_TEXT',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'CONFIRMED_PAGE_CONFIRMED_TEXT',
    CONFIRMED_PAGE_DESCRIPTION: 'CONFIRMED_PAGE_DESCRIPTION',
    CONFIRMED_PAGE_FOOTER_TITLE: 'CONFIRMED_PAGE_FOOTER_TITLE',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'CONFIRMED_PAGE_FOOTER_DESCRIPTION',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'CONFIRMED_PAGE_FOOTER_BTN_TEXT',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT',
    LNG_NAME_EN: 'LNG_NAME_EN',
    LNG_NAME_AR: 'LNG_NAME_AR',
    LNG_NAME_ZH_CN: 'LNG_NAME_ZH_CN',
    LNG_NAME_ZH_TW: 'LNG_NAME_ZH_TW',
    LNG_NAME_NL: 'LNG_NAME_NL',
    LNG_NAME_FR: 'LNG_NAME_FR',
    LNG_NAME_DE: 'LNG_NAME_DE',
    LNG_NAME_HE: 'LNG_NAME_HE',
    LNG_NAME_HI: 'LNG_NAME_HI',
    LNG_NAME_IT: 'LNG_NAME_IT',
    LNG_NAME_JA: 'LNG_NAME_JA',
    LNG_NAME_KO: 'LNG_NAME_KO',
    LNG_NAME_FA: 'LNG_NAME_FA',
    LNG_NAME_PT: 'LNG_NAME_PT',
    LNG_NAME_PT_BR: 'LNG_NAME_PT_BR',
    LNG_NAME_RU: 'LNG_NAME_RU',
    LNG_NAME_ES: 'LNG_NAME_ES',
    LNG_NAME_TH: 'LNG_NAME_TH',
    LNG_NAME_TR: 'LNG_NAME_TR',
    LNG_NAME_UK: 'LNG_NAME_UK',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'SCHEDULE_PAGE_SUBTITLE_TEXT',
    HERE_IS_MY_BC_LINK: 'HERE_IS_MY_BC_LINK',
    WECHAT: 'WECHAT',
    MEWE: 'MEWE',
    SNAPCHAT: 'SNAPCHAT',
    PINTEREST: 'PINTEREST',
    TELEGRAM: 'TELEGRAM',
    MEETUP: 'MEETUP',
    MEDIUM: 'MEDIUM',
    TWITCH: 'TWITCH',
    DISCORD: 'DISCORD',
    STEEMIT: 'STEEMIT',
    VK: 'VK',
    QUORA: 'QUORA',
    VIMEO: 'VIMEO',
    ZOOM: 'ZOOM',
    PARLER: 'PARLER',
    HOUSEPARTY: 'HOUSEPARTY',
    TAPEREAL: 'TAPEREAL',
    TUMBLR: 'TUMBLR',
    REDDIT: 'REDDIT',
    OPENING_HOURS_MON: 'OPENING_HOURS_MON',
    OPENING_HOURS_TUE: 'OPENING_HOURS_TUE',
    OPENING_HOURS_WED: 'OPENING_HOURS_WED',
    OPENING_HOURS_THU: 'OPENING_HOURS_THU',
    OPENING_HOURS_FRI: 'OPENING_HOURS_FRI',
    OPENING_HOURS_SAT: 'OPENING_HOURS_SAT',
    OPENING_HOURS_SUN: 'OPENING_HOURS_SUN',
};

export default localizationKeys;
