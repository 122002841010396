import i18n from 'i18next';

import languageCode from '../../consts/localization/languageCode';
import translations_default from './default.json';

export const translate = (key, options) => i18n.t(key, options);

export const getLocalizedStrings = () => i18n.getResource(i18n.options.lng, i18n.options.ns);

export const addResourceBundle = (lng, res) => {
    const resMergedWithDefaultLocals = res;

    for (const [key, value] of Object.entries(translations_default)) {
        resMergedWithDefaultLocals[key] = value;
    }

    i18n.addResourceBundle(lng, 'translation', resMergedWithDefaultLocals, true);
};

export const changeLanguage = async (lngCode = languageCode.EN) => {
    await i18n.changeLanguage(lngCode);
};

export const isRTLLanguage = (lngCode) =>
    lngCode === languageCode.HE || lngCode === languageCode.AR || lngCode === languageCode.FA;

export const getVerifiedLanguage = (lng) =>
    languageCode[String(lng).toUpperCase()] ? languageCode[String(lng).toUpperCase()] : languageCode.EN;
