import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, {Component, createContext} from 'react';

import routePaths from '../consts/route/routePaths';
import api from '../services/api/apiService';
import routerService from '../services/app/routerService';
import {getVerifiedLanguage} from '../services/localization/localeService';

export const DataLayerContext = createContext({});

class DataLayerProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: {},
            selectedTime: '',
            ownerTimeOffset: null,
            ownTime: '',
            card: {
                slug: '',
                full_name: '',
                primary_color: '',
                bg_image: '',
                address: '',
                phones: [],
                email: '',
                logo: '',
                job_title: '',
                business_name: '',
                business_description: '',
                opening_hours: [],
                social_profiles: [],
                website: '',
                mode: 'published',
                user_type: '',
            },
            messageSent: false,
            errorMessage: '',
            errorGetCard: '',
            errorSetClient: '',
            timeSendError: '',
            loadingGetCard: false,
            timeSend: false,
            primary_color: '',
            dataToBack: null,
            isToday: true,
            is24: true,
            isBC: false,
        };
    }

    setClient = (callbackID, isBC) => {
        this.setState({
            errorSetClient: '',
        });
        // const isBC = window.localStorage.getItem('source') === 'bc';
        try {
            api.getUserInfo({
                callback_id: callbackID,
                isBusinessCard: isBC,
            })
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            client: res.data && res.data.data,
                            ownerTimeOffset: res.data.data.utc_offset,
                            isBC,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        errorSetClient: error,
                    });
                    console.error(error);
                });
        } catch (e) {
            this.setState({
                errorSetClient: e,
            });
            console.error(e);
        }
    };

    getBusinessCard = (callbackID, history) => {
        this.setState({
            loadingGetCard: true,
            errorGetCard: '',
        });
        try {
            api.getBusinessCard({
                callback_id: callbackID,
            })
                .then((res) => {
                    if (res.status === 200 && res.data) {
                        i18next.changeLanguage(getVerifiedLanguage(res?.data?.language)).then(() => {
                            this.setState({
                                card: res.data,
                                loadingGetCard: false,
                            });
                            res.data.primary_color &&
                                this.setState({
                                    primary_color: res.data.primary_color,
                                });
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        history.push(routePaths.ERROR_404);
                    }
                    this.setState({
                        errorGetCard: error,
                        loadingGetCard: false,
                    });
                    console.error(error);
                });
        } catch (e) {
            this.setState({
                errorGetCard: e,
                loadingGetCard: false,
            });
            console.error(e);
        }
    };

    putMessage = (data) => {
        try {
            api.putMessage(data)
                .then((res) => {
                    if (res.status > 200 && res.status < 304) {
                        this.setState({
                            messageSent: true,
                            errorMessage: '',
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        errorMessage: error,
                    });
                    console.error(error);
                });
        } catch (e) {
            this.setState({
                errorMessage: e,
            });
            console.error(e);
        }
    };

    sendTime = (data) => {
        let dataToBack = {
            callback_id: data.callback_id,
            body: data.body,
            isBusinessCard: false,
        };

        if (this.state.isBC) {
            dataToBack = {
                callback_id: this.state?.dataToBack?.callback_id,
                body: {
                    ...this.state.dataToBack?.body,
                    type: data.body?.type,
                    name: data.body.name,
                    phone: data.body.phone,
                },
                isBusinessCard: !!this.state.isBC,
            };

            if (this.state?.dataToBack?.body?.type) {
                dataToBack.body.type = this.state?.dataToBack?.body?.type;
            }
        }

        try {
            api.setUserInfo(dataToBack)
                .then((res) => {
                    if (res.status > 200 && res.status < 304) {
                        this.setState({
                            timeSend: true,
                        });
                    } else {
                        this.setState({
                            timeSendError: true,
                        });
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        this.setState({
                            timeSendError: err.response,
                        });
                    } else {
                        this.setState({
                            timeSendError: err,
                        });
                    }
                    console.error(err);
                });
        } catch (e) {
            this.setState({
                errorMessage: e,
            });
            console.error(e);
        }
    };

    clearTimeSendError = () => {
        this.setState({
            timeSendError: '',
        });
    };

    setDataToBack = (data) => {
        this.setState({
            dataToBack: data,
        });
    };

    setIsToday = (data) => {
        this.setState({
            isToday: data,
        });
    };

    set24Format = (data) => {
        this.setState({
            is24: data,
        });
    };

    setMessageSent = (value) => {
        this.setState({
            messageSent: value,
        });
    };

    clearError = () => {
        this.setState({
            errorMessage: '',
        });
    };

    setClientSelectedTime = (selectedTime, day) => {
        let time = selectedTime;

        day.toLowerCase() === 'today' && (time = `${day} ${selectedTime}`);
        day.toLowerCase() === 'tomorrow' && (time = `${day} ${selectedTime}`);
        this.setState({selectedTime: time});
    };

    setOwnTime = (ownTime) => {
        this.setState({ownTime});
    };

    setOwnTimeOffset = (timeOffset) => {
        this.setState({ownerTimeOffset: timeOffset});
    };

    render() {
        const {children} = this.props;

        return (
            <DataLayerContext.Provider
                value={{
                    ...this.state,
                    setClientSelectedTime: this.setClientSelectedTime,
                    setClient: this.setClient,
                    putMessage: this.putMessage,
                    getBusinessCard: this.getBusinessCard,
                    setOwnTime: this.setOwnTime,
                    setOwnTimeOffset: this.setOwnTimeOffset,
                    setMessageSent: this.setMessageSent,
                    setDataToBack: this.setDataToBack,
                    sendTime: this.sendTime,
                    clearTimeSendError: this.clearTimeSendError,
                    clearError: this.clearError,
                    setIsToday: this.setIsToday,
                    set24Format: this.set24Format,
                }}
            >
                {children}
            </DataLayerContext.Provider>
        );
    }
}

DataLayerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DataLayerProvider;
