export default {
    CONFIRM: 'التأكيد',
    OOPS_COMMA: 'عفوا',
    SOMETHING_WENT_WRONG_ACCENT: 'حدث شيء خطأ',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'الصفحة التي تبحث عنها لم تعد موجودة.',
    BACK_TO_HOME: 'العودة إلى الصفحة الرئيسية',
    PICK_A_TIM: 'اختر وقتًا',
    TODAY: 'اليوم',
    TOMORROW: 'غدًا',
    SELECT_TIME: 'حدد وقت',
    TEXT_24HR: '24 ساعة',
    SCHEDULE_CALL: 'جدولة مكالمة',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'فيس بوك',
    TIKTOK: 'تيك توك',
    INSTAGRAM: 'إنستجرام',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'تويتر',
    CALL: 'الإتّصال',
    EMAIL: 'البريد الإلكتروني',
    WEBSITE: 'الموقع الإلكتروني',
    OPENING_HOURS: 'ساعات العمل',
    BC_ABOUT_TITLE: 'نبذة مُختصرة',
    BC_ADDRESS_TITLE: 'العُنوان',
    BC_NAVIGATE_BTN_TEXT: 'التنقل',
    BC_WEBSITE_TITLE: 'الموقع الإلكتروني',
    BC_CONTACT_US_TITLE: 'تواصل معنا',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'شكرًا لك!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'لقد تم إرسال رسالتك بنجاح',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'اسم غير صحيح',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'اسمك',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'رقم غير صحيح',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'رقم الهاتف',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'اكتب رسالة',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'الإرسال',
    BC_FOLLOW_US_TITLE: 'تابعنا',
    BC_SHARE_WITH_FRIENDS_TITLE: 'شارك مع الأصدقاء',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'الحفظ في دفتر العناوين',
    FOOTER_CREATE_BC_PROMO_TEXT: 'قم بإنشاء بطاقة العمل الخاصة بك',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'جدولة مكالمة',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'الوقت المناسب لمعاودة الاتصال:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'التأكيد',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'اختر وقتًا',
    SCHEDULE_TIME_ASAP_TEXT: 'في أسرع وقت ممكن',
    SCHEDULE_TIME_30MIN_TEXT: 'في 30 دقيقة',
    SCHEDULE_TIME_1H_TEXT: 'في ساعة واحدة',
    PICK_PAGE_PICK_A_TIME_TITLE: 'اختر وقتًا',
    PICK_PAGE_SELECT_WHEN_POINT: 'حدد التاريخ',
    PICK_PAGE_TODAY_BTN_TEXT: 'اليوم',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'غدًا',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'المُتابعة',
    TIME_LIST_IN_15_MIN: 'في 15 دقيقة',
    TIME_LIST_IN_30_MIN: 'في 30 دقيقة',
    TIME_LIST_IN_1_H: 'في ساعة واحدة',
    TIME_LIST_AT_TIME: 'في {{hours}} : 00',
    TIME_LIST_AT_TIME_AMPM: 'في {{hours}} : 00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'اليوم',
    IDENT_PAGE_TOMORROW_TEXT: 'غدًا',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} في {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'اسم غير صالح',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'الاسم بالكامل',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'رقم هاتف غير صالح',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'رقم الهاتف',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'شكرًا لك!',
    CONFIRMED_PAGE_DESCRIPTION: 'تمت جدولة المكالمة بنجاح في {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'هل تتلقى الكثير من مكالمات العمل؟',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'إدارة العملاء المُحتملين وكسب المزيد من العملاء مع Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'جرب مجانًا',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'المتابعة',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'المُتابعة',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'البحث عن الدولة',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'لنتحدث. يُرجى إعلامي بـ أفضل وقت للاتصال بك:',
    HERE_IS_MY_BC_LINK: 'هنا رابط بطاقة العمل: {{link}}',
    OPENING_HOURS_MON: 'الإثنين',
    OPENING_HOURS_TUE: 'الثلاثاء',
    OPENING_HOURS_WED: 'الأربعاء',
    OPENING_HOURS_THU: 'الخميس',
    OPENING_HOURS_FRI: 'الجمعة',
    OPENING_HOURS_SAT: 'السبت',
    OPENING_HOURS_SUN: 'الأحد',
};
