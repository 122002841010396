export default {
    CONFIRM: 'Onayla',
    OOPS_COMMA: 'Oops,',
    SOMETHING_WENT_WRONG_ACCENT: 'Bir şeyler ters gitti.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'Aradığınız sayfa artık mevcut değil.',
    BACK_TO_HOME: 'Ana sayfaya dön',
    PICK_A_TIM: 'Bir Zaman Seçin',
    TODAY: 'Bugün',
    TOMORROW: 'Yarın',
    SELECT_TIME: 'Zaman Seçin',
    TEXT_24HR: '24 saat',
    SCHEDULE_CALL: 'Arama Planla',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Ara',
    EMAIL: 'E-posta',
    WEBSITE: 'İnternet sitesi',
    OPENING_HOURS: 'Çalışma saatleri',
    BC_ABOUT_TITLE: 'Hakkında',
    BC_ADDRESS_TITLE: 'Adres',
    BC_NAVIGATE_BTN_TEXT: 'Gezin',
    BC_WEBSITE_TITLE: 'İnternet sitesi',
    BC_CONTACT_US_TITLE: 'Bize Ulaşın',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Teşekkürler!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Mesajınız başarıyla gönderildi',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Geçersiz isim',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'Adınız',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Geçersiz numara',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Telefon numarası',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Bir mesaj yazın...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Gönder',
    BC_FOLLOW_US_TITLE: 'Bizi takip edin',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Arkadaşlarla paylaş',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Adres defterine kaydet',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Kartvizitinizi oluşturun',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Arama Planla',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Geri arama zamanı:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Onayla',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Bir zaman seçin',
    SCHEDULE_TIME_ASAP_TEXT: 'en kısa sürede',
    SCHEDULE_TIME_30MIN_TEXT: '30 dakika içinde',
    SCHEDULE_TIME_1H_TEXT: '1 saat içinde',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Bir Zaman Seçin',
    PICK_PAGE_SELECT_WHEN_POINT: 'Zaman Seçin:',
    PICK_PAGE_TODAY_BTN_TEXT: 'Bugün',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Yarın',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Devam',
    TIME_LIST_IN_15_MIN: '15 dakika içinde',
    TIME_LIST_IN_30_MIN: '30 dakika içinde',
    TIME_LIST_IN_1_H: '1 saat içinde',
    TIME_LIST_AT_TIME: 'Saat: {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'Saat: :00 {{amPm}} {{hours}}',
    IDENT_PAGE_TODAY_TEXT: 'Bugün',
    IDENT_PAGE_TOMORROW_TEXT: 'Yarın',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} saat {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Geçersiz isim',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Ad Soyad',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Geçersiz telefon numarası',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Telefon Numarası',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Teşekkürler!',
    CONFIRMED_PAGE_DESCRIPTION: 'Arama başarıyla saat {{selectedTime}} için planlandı.',
    CONFIRMED_PAGE_FOOTER_TITLE: 'İş ile ilgili çok fazla görüşme mi yapıyorsunuz?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Leader ile potansiyel müşterilerinizi yönetin ve daha fazla müşteri kazanın',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Ücretsiz Deneyin',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Onaylandı',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Devam',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Ülke arayın',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'Haydi konuşalım. Lütfen size ulaşabileceğim en uygun zamanı bana bildirin:',
    HERE_IS_MY_BC_LINK: 'Kartvizit bağlantım: {{link}}',
    OPENING_HOURS_MON: 'Pzt',
    OPENING_HOURS_TUE: 'Sal',
    OPENING_HOURS_WED: 'Çar',
    OPENING_HOURS_THU: 'Per',
    OPENING_HOURS_FRI: 'Cum',
    OPENING_HOURS_SAT: 'Cts',
    OPENING_HOURS_SUN: 'Pzr',
};
