import {configureStore} from '@reduxjs/toolkit';
import logger from 'redux-logger';

import globalReducer from './reducers/global';

const store = configureStore({
    reducer: {
        global: globalReducer,
    },
    middleware: (getDefaultMiddleware) => {
        if (process.env.NODE_ENV === 'development') {
            return getDefaultMiddleware().concat(logger);
        } else {
            return getDefaultMiddleware();
        }
    },
});

export const dispatch = store.dispatch;

export default store;
