export default {
    CONFIRM: 'Підтвердити',
    OOPS_COMMA: 'Ой,',
    SOMETHING_WENT_WRONG_ACCENT: 'Щось пішло не так',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'Сторінка, яку ви шукаєте, більше не існує.',
    BACK_TO_HOME: 'Повернутися на головну сторінку',
    PICK_A_TIM: 'Вибрати час',
    TODAY: 'Сьогодні',
    TOMORROW: 'Завтра',
    SELECT_TIME: 'Виберіть час',
    TEXT_24HR: '24 год',
    SCHEDULE_CALL: 'Запланувати дзвінок',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tik Tok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Дзвінок',
    EMAIL: 'Email',
    WEBSITE: 'Веб-сайт',
    OPENING_HOURS: 'Години роботи',
    BC_ABOUT_TITLE: 'Інформація',
    BC_ADDRESS_TITLE: 'Адреса',
    BC_NAVIGATE_BTN_TEXT: 'Навігація',
    BC_WEBSITE_TITLE: 'Веб-сайт',
    BC_CONTACT_US_TITLE: "Зв'язатися з нами",
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Дякуємо!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Ваше повідомлення успішно відправлено',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: "Недійсне ім'я",
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: "Ваше ім'я",
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Недійсний номер телефону',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Номер телефону',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Написати повідомлення',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Надіслати',
    BC_FOLLOW_US_TITLE: 'Підписуйтесь на нас',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Поділитися з друзями',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Зберегти в адресній книзі',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Створіть власну візитку',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Запланувати дзвінок',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Коли передзвонити:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Підтвердити',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Вибрати час',
    SCHEDULE_TIME_ASAP_TEXT: 'В найближчий час',
    SCHEDULE_TIME_30MIN_TEXT: 'Через 30 хв',
    SCHEDULE_TIME_1H_TEXT: 'Через 1 годину',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Вибрати час',
    PICK_PAGE_SELECT_WHEN_POINT: 'Вибрати час:',
    PICK_PAGE_TODAY_BTN_TEXT: 'Сьогодні',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Завтра',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Продовжити',
    TIME_LIST_IN_15_MIN: 'Через 15 хв',
    TIME_LIST_IN_30_MIN: 'Через 30 хв',
    TIME_LIST_IN_1_H: 'Через 1 годину',
    TIME_LIST_AT_TIME: 'О {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'О {{hours}}:00',
    IDENT_PAGE_TODAY_TEXT: 'Сьогодні',
    IDENT_PAGE_TOMORROW_TEXT: 'Завтра',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} о {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: "Недійсне ім'я",
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: "Повне ім'я",
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Недійсний номер телефону',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Номер телефону',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Дякуємо',
    CONFIRMED_PAGE_DESCRIPTION: 'Дзвінок успішно заплановано на {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'Занадто багато ділових дзвінків?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Керуйте лідами та залучайте нових клієнтів за допомогою Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Спробуйте безкоштовно',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Підтвердити',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Продовжити',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Знайти країну',
    SCHEDULE_PAGE_SUBTITLE_TEXT: "Давайте поспілкуємось. Коли з вами найкраще зв'язатися?",
    HERE_IS_MY_BC_LINK: 'Ось посилання на мою візитну картку: {{link}}',
    OPENING_HOURS_MON: 'Пн',
    OPENING_HOURS_TUE: 'Вт',
    OPENING_HOURS_WED: 'Ср',
    OPENING_HOURS_THU: 'Чт',
    OPENING_HOURS_FRI: 'Пт',
    OPENING_HOURS_SAT: 'Сб',
    OPENING_HOURS_SUN: 'Нд',
};
