import {createBrowserHistory} from 'history';

let history;

const getBrowserHistory = () => {
    if (!history) {
        history = createBrowserHistory();
    }

    return history;
};

export default getBrowserHistory;
