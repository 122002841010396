export default {
    CONFIRM: 'पुष्टि करें',
    OOPS_COMMA: 'ऊप्स,',
    SOMETHING_WENT_WRONG_ACCENT: 'कुछ गलत हो गया।',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'आप जिस पेज की तलाश कर रहे हैं वह अब मौजूद नहीं है।',
    BACK_TO_HOME: 'होम पर वापस जाएं',
    PICK_A_TIM: 'एक समय चुनें',
    TODAY: 'आज',
    TOMORROW: 'कल',
    SELECT_TIME: 'समय चुनें',
    TEXT_24HR: '24 घंटे',
    SCHEDULE_CALL: 'कॉल शिड्यूल करें',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'कॉल करें',
    EMAIL: 'ईमेल',
    WEBSITE: 'वेबसाइट',
    OPENING_HOURS: 'खुलने का समय',
    BC_ABOUT_TITLE: 'इनके बारे में',
    BC_ADDRESS_TITLE: 'पता',
    BC_NAVIGATE_BTN_TEXT: 'नेविगेट करें',
    BC_WEBSITE_TITLE: 'वेबसाइट',
    BC_CONTACT_US_TITLE: 'हमसे संपर्क करें',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'धन्यवाद!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'आपका संदेश सफलतापूर्वक भेज दिया गया था',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'अमान्य नाम',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'आपका नाम',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'अमान्य नाम',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'फोन नंबर',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'एक संदेश लिखें ...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'भेजें',
    BC_FOLLOW_US_TITLE: 'हमें फॉलो करें',
    BC_SHARE_WITH_FRIENDS_TITLE: 'दोस्तों के साथ शेयर करें',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'पता पुस्तिका में सेव करें',
    FOOTER_CREATE_BC_PROMO_TEXT: 'अपना खुद का व्बिजनेस कार्ड बनाएं',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'एक कॉल शिड्यूल करें',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'वापस कब कॉल करें:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'पुष्टि करें',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'एक समय चुनें',
    SCHEDULE_TIME_ASAP_TEXT: 'यथाशीघ्र',
    SCHEDULE_TIME_30MIN_TEXT: '30 मिनट में',
    SCHEDULE_TIME_1H_TEXT: '1 घंटे में',
    PICK_PAGE_PICK_A_TIME_TITLE: 'एक समय चुनें',
    PICK_PAGE_SELECT_WHEN_POINT: 'चुनें जब:',
    PICK_PAGE_TODAY_BTN_TEXT: 'आज',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'कल',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'जारी रखें',
    TIME_LIST_IN_15_MIN: '15 मिनट में',
    TIME_LIST_IN_30_MIN: '30 मिनट में',
    TIME_LIST_IN_1_H: '1 घंटे में',
    TIME_LIST_AT_TIME: '{{hours}}:00 बजे',
    TIME_LIST_AT_TIME_AMPM: '{{hours}}:00 {{amPm}} बजे',
    IDENT_PAGE_TODAY_TEXT: 'आज',
    IDENT_PAGE_TOMORROW_TEXT: 'कल',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} {{time}} बजे',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'अमान्य नाम',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'पूरा नाम',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'अमान्य फोन नंबर',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'फोन नंबर',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'धन्यवाद!',
    CONFIRMED_PAGE_DESCRIPTION: 'कॉल {{selectedTime}} के लिए सफलतापूर्वक शेड्यूल किया गया',
    CONFIRMED_PAGE_FOOTER_TITLE: 'बहुत सारे बिजनेस कॉल्स हैं?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Leader ऐप के साथ लीड प्रबंधित करें और अधिक ग्राहकों को जीतें',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'मुफ्त में आजमाएँ',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'पुष्टि हो गई',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'जारी रखें',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'देश खोजें',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'चलिए बात करते है। कृपया मुझे बताएं कि आप तक पहुंचने का सबसे अच्छा समय कब है:',
    HERE_IS_MY_BC_LINK: 'यह रहा मेरा बिजनेस कार्ड लिंक: {{link}}',
    OPENING_HOURS_MON: 'सोम',
    OPENING_HOURS_TUE: 'मंगल',
    OPENING_HOURS_WED: 'बुध',
    OPENING_HOURS_THU: 'गुरु',
    OPENING_HOURS_FRI: 'शुक्र',
    OPENING_HOURS_SAT: 'शनि',
    OPENING_HOURS_SUN: 'रवि',
};
