export default {
    CONFIRM: 'Confirmar',
    OOPS_COMMA: 'Oops,',
    SOMETHING_WENT_WRONG_ACCENT: 'Algo salió mal.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'La página que estás buscando ya no existe.',
    BACK_TO_HOME: 'Volver al inicio',
    PICK_A_TIM: 'Elige una hora',
    TODAY: 'Hoy',
    TOMORROW: 'Mañana',
    SELECT_TIME: 'Seleccionar hora',
    TEXT_24HR: '24 hr',
    SCHEDULE_CALL: 'Programar llamada',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'TikTok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Llamar',
    EMAIL: 'Correo electrónico',
    WEBSITE: 'Sitio web',
    OPENING_HOURS: 'Horario de apertura',
    BC_ABOUT_TITLE: 'Acerca de',
    BC_ADDRESS_TITLE: 'Dirección',
    BC_NAVIGATE_BTN_TEXT: 'Navegar',
    BC_WEBSITE_TITLE: 'Sitio web',
    BC_CONTACT_US_TITLE: 'Contáctanos',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: '¡Gracias!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Tu mensaje fue enviado exitosamente',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Nombre inválido',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'Tu nombre',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Número inválido',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Número de teléfono',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Escribe un mensaje...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Enviar',
    BC_FOLLOW_US_TITLE: 'Síguenos',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Compartir con amigos',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Guardar en la libreta de direcciones',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Crea tu propia tarjeta de presentación',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Programar una llamada',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Cuándo devolver la llamada:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Confirmar',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Elige una hora',
    SCHEDULE_TIME_ASAP_TEXT: 'Lo antes posible',
    SCHEDULE_TIME_30MIN_TEXT: 'en 30 min',
    SCHEDULE_TIME_1H_TEXT: 'En 1 hora',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Elige una hora',
    PICK_PAGE_SELECT_WHEN_POINT: 'Seleccionar cuando:',
    PICK_PAGE_TODAY_BTN_TEXT: 'Hoy',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Mañana',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Continuar',
    TIME_LIST_IN_15_MIN: 'En 15 min',
    TIME_LIST_IN_30_MIN: 'En 30 min',
    TIME_LIST_IN_1_H: 'En 1 hora',
    TIME_LIST_AT_TIME: 'A las {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'A las {{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'Hoy',
    IDENT_PAGE_TOMORROW_TEXT: 'Mañana',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} a las {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Nombre inválido',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Nombre completo',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Número de teléfono inválido',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Número de teléfono',
    CONFIRMED_PAGE_CONFIRMED_TEXT: '¡Gracias!',
    CONFIRMED_PAGE_DESCRIPTION: 'La llamada se programó correctamente para {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: '¿Tienes muchas llamadas de negocios?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Gestiona prospectos y gana más clientes con Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Prueba gratis',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Confirmado',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Continuar',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Buscar país',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'Hablemos. Por favor, avísame cuando sea el mejor momento para comunicarme contigo:',
    HERE_IS_MY_BC_LINK: 'Aquí está el enlace de mi tarjeta de presentación: {{link}}',
    OPENING_HOURS_MON: 'Lun',
    OPENING_HOURS_TUE: 'Mar',
    OPENING_HOURS_WED: 'Mié',
    OPENING_HOURS_THU: 'Jue',
    OPENING_HOURS_FRI: 'Vie',
    OPENING_HOURS_SAT: 'Sáb',
    OPENING_HOURS_SUN: 'Dom',
};
