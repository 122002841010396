export default {
    CONFIRM: 'تأیید',
    OOPS_COMMA: 'اوه،',
    SOMETHING_WENT_WRONG_ACCENT: 'مشکلی رخ داده است',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'صفحه ای که به دنبالش هستید، دیگر وجود ندارد',
    BACK_TO_HOME: 'بازگشت به صفحه اصلی',
    PICK_A_TIM: 'انتخاب یک زمان',
    TODAY: 'امروز',
    TOMORROW: 'فردا',
    SELECT_TIME: 'انتخاب زمان',
    TEXT_24HR: '24 ساعت',
    SCHEDULE_CALL: 'زمانبندی و برنامه ریزی تماس',
    WHATSAPP: 'واتس اپ',
    FACEBOOK: 'فیسبوک',
    TIKTOK: 'تیک تاک',
    INSTAGRAM: 'اینستاگرام',
    LINKEDIN: 'لینکدین',
    TWITTER: 'توییتر',
    CALL: 'تماس',
    EMAIL: 'ایمیل',
    WEBSITE: 'وب سایت',
    OPENING_HOURS: 'ساعات کاری',
    BC_ABOUT_TITLE: 'درباره',
    BC_ADDRESS_TITLE: 'آدرس',
    BC_NAVIGATE_BTN_TEXT: 'پیمایش',
    BC_WEBSITE_TITLE: 'وب سایت',
    BC_CONTACT_US_TITLE: 'تماس با ما',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'متشکرم!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'پیام شما با موفقیت ارسال شد',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'نام، نامعتبر است',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'نام',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'شماره تلفن نامعتبر است',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'شماره تلفن',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'نوشتن یک پیام...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'ارسال',
    BC_FOLLOW_US_TITLE: 'ما را دنبال کنید',
    BC_SHARE_WITH_FRIENDS_TITLE: ' به اشتراک گذاری با دوستان',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'ذخیره در دفترچه آدرس',
    FOOTER_CREATE_BC_PROMO_TEXT: 'ایجاد کارت ویزیت',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'زمانبندی و برنامه ریزی تماس',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'زمان تماس چه موقع باشد:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'تایید',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'انتخاب یک زمان',
    SCHEDULE_TIME_ASAP_TEXT: 'در اسرع وقت (هر چه زودتر بهتر)',
    SCHEDULE_TIME_30MIN_TEXT: 'ظرف 30 دقیقه',
    SCHEDULE_TIME_1H_TEXT: 'ظرف 1 ساعت',
    PICK_PAGE_PICK_A_TIME_TITLE: 'انتخاب یک زمان',
    PICK_PAGE_SELECT_WHEN_POINT: 'زمان را انتخاب کنید:',
    PICK_PAGE_TODAY_BTN_TEXT: 'امروز',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'فردا',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'ادامه',
    TIME_LIST_IN_15_MIN: 'ظرف 15 دقیقه',
    TIME_LIST_IN_30_MIN: 'ظرف 30 دقیقه',
    TIME_LIST_IN_1_H: 'ظرف 1 ساعت',
    TIME_LIST_AT_TIME: 'در 00:{{hours}}',
    TIME_LIST_AT_TIME_AMPM: 'در {{amPm}} 00:{{hours}}',
    IDENT_PAGE_TODAY_TEXT: 'امروز',
    IDENT_PAGE_TOMORROW_TEXT: 'فردا',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} در {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'نام، نامعتبر است',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'نام کامل',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'شماره تلفن نامعتبر است',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'شماره تلفن',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'متشکرم!',
    CONFIRMED_PAGE_DESCRIPTION: 'این تماس با موفقیت برای {{selectedTime}} برنامه ریزی و زمانبندی شد',
    CONFIRMED_PAGE_FOOTER_TITLE: 'آیا تماس‌های کاری و تجاری زیادی دارید؟',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION:
        ' با استفاده از Leader می‌توانید مشتریان راغب و بالقوه خود را مدیریت کرده و مشتریان بیشتری بدست آورید',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'نسخه رایگان آن را امتحان کنید',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'تأیید شد',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'ادامه',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'زبان جستجو',
    SCHEDULE_PAGE_SUBTITLE_TEXT:
        'اجازه دهید صحبت کنیم. لطفا به من اطلاع دهید که بهترین زمان برای تماس با شما چه زمانی است:',
    HERE_IS_MY_BC_LINK: 'لینک بیزینس کارت من: {{link}}',
    OPENING_HOURS_MON: 'دوشنبه',
    OPENING_HOURS_TUE: 'سه‌شنبه',
    OPENING_HOURS_WED: 'چهارشنبه',
    OPENING_HOURS_THU: 'پنج‌شنبه',
    OPENING_HOURS_FRI: 'جمعه',
    OPENING_HOURS_SAT: 'شنبه',
    OPENING_HOURS_SUN: 'خورشید',
};
