export default {
    links: {
        leaderAndroidLink: 'https://play.google.com/store/apps/details?id=com.katans.leader&hl=en_US&gl=US',
        leaderIOSLink: 'https://leader.net',
        leaderDesktopLink: 'https://leader.net',
        leaderFacebookLink: 'https://www.facebook.com/leader.app.page',
        leaderTwitterLink: 'https://twitter.com/LEADer__CRM',
        leaderLinkedinLink: 'https://www.linkedin.com/company/leader-crm',
        leaderYoutubeLink: 'https://www.youtube.com/channel/UChEcX5rbLMOH7yLOHF3J6Ww',
        leaderInstagramLink: 'https://instagram.com/leader.crm',
    },
    thirdAppsLinks: {
        wazeLiveMap: 'https://waze.com/en/live-map',
        googleMapLink: 'https://www.google.com/maps/?q=',
        uberLoginLink: 'https://auth.uber.com/login/',
        facebookLink: 'https://facebook.com',
        twitterLink: 'https://twitter.com',
    },
    ga: {
        trackingCode: 'G-G0PV84LBWK',
    },
};
