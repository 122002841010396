export default {
    CONFIRM: 'Confirmer',
    OOPS_COMMA: 'Oups,',
    SOMETHING_WENT_WRONG_ACCENT: 'Quelque chose a mal tourné.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: "La page que vous recherchez n'existe plus.",
    BACK_TO_HOME: "Retour à l'accueil",
    PICK_A_TIM: 'Choisissez un moment',
    TODAY: "Aujourd'hui",
    TOMORROW: 'Demain',
    SELECT_TIME: 'Selectionnez horaire',
    TEXT_24HR: '24 heures',
    SCHEDULE_CALL: 'Appel de programme',
    WHATSAPP: 'Whatsapp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Appelez',
    EMAIL: 'Email',
    WEBSITE: 'Site web',
    OPENING_HOURS: "Heures d'ouverture",
    BC_ABOUT_TITLE: 'À propos',
    BC_ADDRESS_TITLE: 'Adresse',
    BC_NAVIGATE_BTN_TEXT: 'Naviguer',
    BC_WEBSITE_TITLE: 'Site web',
    BC_CONTACT_US_TITLE: 'Contactez-nous',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Merci !',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Votre message a été envoyé avec succès',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Nom invalide',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'Votre nom',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Numéro non valide',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Numéro de téléphone',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Écrire un message...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Envoyer',
    BC_FOLLOW_US_TITLE: 'Suivez-nous',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Partager avec des amis',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: "Enregistrer dans le carnet d'adresses",
    FOOTER_CREATE_BC_PROMO_TEXT: 'Créez votre propre carte de visite',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Planifier un appel',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Quand rappeler :',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Confirmer',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Choisissez un moment',
    SCHEDULE_TIME_ASAP_TEXT: 'DÈS QUE POSSIBLE',
    SCHEDULE_TIME_30MIN_TEXT: 'dans 30 minutes',
    SCHEDULE_TIME_1H_TEXT: 'en 1 heure',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Choisissez un moment',
    PICK_PAGE_SELECT_WHEN_POINT: 'Sélectionnez Quand :',
    PICK_PAGE_TODAY_BTN_TEXT: "Aujourd'hui",
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Demain',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Continuer',
    TIME_LIST_IN_15_MIN: 'Dans 15 minutes',
    TIME_LIST_IN_30_MIN: 'Dans 30 minutes',
    TIME_LIST_IN_1_H: 'Dans 1 heure',
    TIME_LIST_AT_TIME: 'À {{heures}}:00',
    TIME_LIST_AT_TIME_AMPM: 'A {{heures}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: "Aujourd'hui",
    IDENT_PAGE_TOMORROW_TEXT: 'Demain',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{jour}} à {{heure}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Nom invalide',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Nom complet',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Numéro de téléphone invalide',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Numéro de téléphone',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Merci !',
    CONFIRMED_PAGE_DESCRIPTION: "L'appel a été programmé avec succès pour {{selectedTime}}.",
    CONFIRMED_PAGE_FOOTER_TITLE: "Vous avez beaucoup d'appels professionnels ?",
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Gérer les prospects et gagner plus de clients avec Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Essayez gratuitement',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Confirmé',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Continuer',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Pays de recherche',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'Parlons-en. Veuillez me faire savoir quel est le meilleur moment pour vous joindre :',
    HERE_IS_MY_BC_LINK: 'Voici le lien de ma carte de visite : {{link}}',
    OPENING_HOURS_MON: 'Lun',
    OPENING_HOURS_TUE: 'Mar',
    OPENING_HOURS_WED: 'Mer',
    OPENING_HOURS_THU: 'Jeu',
    OPENING_HOURS_FRI: 'Ven',
    OPENING_HOURS_SAT: 'Sam',
    OPENING_HOURS_SUN: 'Dim',
};
