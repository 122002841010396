export default {
    CONFIRM: '確認',
    OOPS_COMMA: 'おっと',
    SOMETHING_WENT_WRONG_ACCENT: '何かがうまくいかなかった。',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'お探しのページはもう存在しません。',
    BACK_TO_HOME: 'ホームに戻る',
    PICK_A_TIM: '時間を選ぶ',
    TODAY: '今日',
    TOMORROW: '明日',
    SELECT_TIME: '時間の選択',
    TEXT_24HR: '24時間',
    SCHEDULE_CALL: 'コールをスケジュール',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: '電話',
    EMAIL: '電子メール',
    WEBSITE: 'ウェブサイト',
    OPENING_HOURS: '営業時間',
    BC_ABOUT_TITLE: '概要',
    BC_ADDRESS_TITLE: '住所',
    BC_NAVIGATE_BTN_TEXT: 'ナビゲート',
    BC_WEBSITE_TITLE: 'ウェブサイト',
    BC_CONTACT_US_TITLE: 'お問い合わせ',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'ありがとうございました！',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'あなたのメッセージは正常に送信されました。',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: '無効な名前',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'あなたの名前',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: '無効な番号',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: '電話番号',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'メッセージを書く',
    BC_CONTACT_US_BUTTON_SEND_TEXT: '送信',
    BC_FOLLOW_US_TITLE: 'フォローする',
    BC_SHARE_WITH_FRIENDS_TITLE: '友達と共有する',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'アドレス帳に保存',
    FOOTER_CREATE_BC_PROMO_TEXT: '独自の名刺を作成する',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'コールをスケジュール',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'いつコールバックするか：',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: '確認',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: '時間を選ぶ',
    SCHEDULE_TIME_ASAP_TEXT: 'できるだけ速くに',
    SCHEDULE_TIME_30MIN_TEXT: '30分で',
    SCHEDULE_TIME_1H_TEXT: '1時間で',
    PICK_PAGE_PICK_A_TIME_TITLE: '時間を選ぶ',
    PICK_PAGE_SELECT_WHEN_POINT: 'いつか選択：',
    PICK_PAGE_TODAY_BTN_TEXT: '今日',
    PICK_PAGE_TOMORROW_BTN_TEXT: '明日',
    PICK_PAGE_CONTINUE_BTN_TEXT: '続ける',
    TIME_LIST_IN_15_MIN: '15分で',
    TIME_LIST_IN_30_MIN: '30分で',
    TIME_LIST_IN_1_H: '1時間で',
    TIME_LIST_AT_TIME: '{{hours}} ：00',
    TIME_LIST_AT_TIME_AMPM: '{{hours}} ：00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: '今日',
    IDENT_PAGE_TOMORROW_TEXT: '明日',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}}で{{time}}。',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: '無効な名前',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'フルネーム',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: '無効な電話番号',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: '電話番号',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'ありがとうございました！',
    CONFIRMED_PAGE_DESCRIPTION: '{{selectedTime}}に正常にスケジュールされました',
    CONFIRMED_PAGE_FOOTER_TITLE: '多くのビジネスコールがありますか?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'リードを管理し、リーダーでより多くの顧客を獲得する',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: '無料で試す',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: '確認済み',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: '続ける',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: '国を検索',
    SCHEDULE_PAGE_SUBTITLE_TEXT: '話しましょう。あなたに連絡を取るのに最も適した時間帯を教えてください。',
    HERE_IS_MY_BC_LINK: 'ここに私の名刺のリンクがあります: {{link}}',
    OPENING_HOURS_MON: '月',
    OPENING_HOURS_TUE: '火',
    OPENING_HOURS_WED: '水',
    OPENING_HOURS_THU: '木',
    OPENING_HOURS_FRI: '金',
    OPENING_HOURS_SAT: '土',
    OPENING_HOURS_SUN: '日',
};
