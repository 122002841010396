import cn from 'classnames';
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import styles from './TextInput.module.scss';

const TextInput = (
    {
        type = 'text',
        className,
        value,
        onChange,
        placeholder,
        pattern,
        errorMessage,
        isTextarea,
        name,
        isMandatory,
        maxLength,
    },
    ref
) => {
    const [isError, setIsError] = useState(false);
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
        isValid: () => (isTextarea ? !!value : !!value && !isError),
    }));

    const onBlur = () => {
        if (value === '' && isMandatory) {
            setIsError(true);
            onChange('Field is mandatory!');
        }
        if (pattern && value !== '') {
            const isValueValid = new RegExp(pattern).test(value.replaceAll(' ', ''));

            if (!isValueValid) {
                setIsError(!isValueValid);
                onChange(errorMessage);
            }
        }
    };

    const onFocus = () => {
        if (isError) {
            setIsError(false);
            onChange('');
        }
    };

    const onChangeHandler = ({target}) => onChange(String(target.value));

    return isTextarea ? (
        <textarea
            ref={inputRef}
            value={value}
            onChange={onChangeHandler}
            placeholder={placeholder}
            className={cn(styles.InputGeneral, className, {[styles.Filled]: !!value})}
            name={name}
            maxLength={maxLength}
        />
    ) : (
        <input
            name={name}
            ref={inputRef}
            type={type}
            className={cn(styles.InputGeneral, className, {
                [styles.Filled]: !!value && !isError,
                [styles.Error]: isError,
            })}
            value={value}
            onChange={onChangeHandler}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
};

export default forwardRef(TextInput);
