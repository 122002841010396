import {createAction, createReducer} from '@reduxjs/toolkit';

const initialState = {
    isRTLLanguageSelected: false,
    isWebview: null,
};

export const setIsRTLLanguageSelected = createAction('SET_IS_RTL_LANGUAGE_SELECTED');

export const setIsWebview = createAction('SET_IS_WEBVIEW');

const globalReducer = createReducer(initialState, (builder) => {
    builder.addCase(setIsRTLLanguageSelected.type, (state, {payload}) => {
        state.isRTLLanguageSelected = payload;
    });
    builder.addCase(setIsWebview.type, (state, {payload}) => {
        state.isWebview = payload;
    });
});

export default globalReducer;
