export default {
    CONFIRM: 'Bestätigen',
    OOPS_COMMA: 'Hoppla,',
    SOMETHING_WENT_WRONG_ACCENT: 'Etwas ist falsch gelaufen.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'Die gesuchte Seite existiert nicht mehr.',
    BACK_TO_HOME: 'Zurück nach Hause',
    PICK_A_TIM: 'Wählen Sie eine Zeit',
    TODAY: 'Heute',
    TOMORROW: 'Morgen',
    SELECT_TIME: 'Zeit wählen',
    TEXT_24HR: '24 Stunden',
    SCHEDULE_CALL: 'Anruf planen',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Anruf',
    EMAIL: 'E-Mail',
    WEBSITE: 'Website',
    OPENING_HOURS: 'Öffnungszeiten',
    BC_ABOUT_TITLE: 'Über',
    BC_ADDRESS_TITLE: 'Adresse',
    BC_NAVIGATE_BTN_TEXT: 'Navigieren',
    BC_WEBSITE_TITLE: 'Website',
    BC_CONTACT_US_TITLE: 'Uns kontaktieren',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Danke sehr!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Ihre Nachricht wurde erfolgreich gesendet',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Ungültiger Name',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'Dein Name',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Ungültige Telefonnummer',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Telefonnummer',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Eine Nachricht schreiben...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Senden',
    BC_FOLLOW_US_TITLE: 'Folgen Sie uns',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Mit Freunden teilen',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Im Adressbuch speichern',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Erstellen Sie Ihre eigene Visitenkarte',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Einen Anruf planen',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Wann Sie zurückrufen sollten:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Bestätigen',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Wählen Sie eine Zeit',
    SCHEDULE_TIME_ASAP_TEXT: 'ASAP',
    SCHEDULE_TIME_30MIN_TEXT: 'in 30 min ',
    SCHEDULE_TIME_1H_TEXT: 'in 1 Stunde',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Wählen Sie eine Zeit',
    PICK_PAGE_SELECT_WHEN_POINT: 'Wählen Sie Wann:',
    PICK_PAGE_TODAY_BTN_TEXT: 'Heute',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Morgen',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Fortfahren',
    TIME_LIST_IN_15_MIN: 'In 15 min ',
    TIME_LIST_IN_30_MIN: 'In 30 min ',
    TIME_LIST_IN_1_H: 'In 1 Stunde',
    TIME_LIST_AT_TIME: 'Um {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'Um {{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'Heute',
    IDENT_PAGE_TOMORROW_TEXT: 'Morgen',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} um {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Ungültiger Name',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Vollständiger Name',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Ungültige Telefonnummer',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Telefonnummer',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Danke sehr!',
    CONFIRMED_PAGE_DESCRIPTION: 'Der Anruf wurde erfolgreich für {{selectedTime}} geplant',
    CONFIRMED_PAGE_FOOTER_TITLE: 'Führen Sie viele Geschäftsgespräche?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Verwalten Sie Leads und gewinnen Sie mehr Kunden mit Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Kostenlos ausprobieren',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Bestätigt',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Fortfahren',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Sprache suchen',
    SCHEDULE_PAGE_SUBTITLE_TEXT:
        'Lass uns reden. Bitte lassen Sie mich wissen, wann die beste Zeit ist, um Sie zu erreichen:',
    HERE_IS_MY_BC_LINK: 'Hier ist der Link zu meiner Visitenkarte: {{link}}',
    OPENING_HOURS_MON: 'Mo.',
    OPENING_HOURS_TUE: 'Di.',
    OPENING_HOURS_WED: 'Mi.',
    OPENING_HOURS_THU: 'Do.',
    OPENING_HOURS_FRI: 'Fr.',
    OPENING_HOURS_SAT: 'Sa.',
    OPENING_HOURS_SUN: 'So.',
};
