import ReactGA from 'react-ga4';

import appConfig from '../../config/appConfig';

export const initGA = () => {
    ReactGA.initialize(appConfig.getGATrackingCode());
};

export const Event = (category, action, label) => {
    ReactGA.event({
        category,
        action,
        label,
    });
};
