import cn from 'classnames';
import i18n from 'i18next';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import SVGSearchIcon from '../../components/UI/icons/utility-icons/SVGSearchIcon';
import TextInput from '../../components/UI/TextInput/TextInput';
import localizationKeys from '../../consts/localization/localizationKeys';
import {changeLanguage, translate} from '../../services/localization/localeService';
import {makeSelectIsWebview} from '../../store/selectors/global';
import styles from './LanguageSelect.module.scss';
import {getLanguageSelectData} from './languageSelectData';

const LanguageSelect = ({isForMobile, isForDesktop, layout}) => {
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [filterValue, setFilterValue] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const isWebview = useSelector(makeSelectIsWebview());
    const {t} = useTranslation();
    const modalWrapperRef = useRef(null);
    const listWrapperRef = useRef(null);
    const {isMobile} = layout || {};
    const isVisible = isForMobile ? isMobile : !isMobile && isForDesktop;

    useEffect(() => {
        getLanguageSelectData().then((dataArr) => {
            setData(dataArr);
            setFilteredData(dataArr);
        });
    }, []);

    useEffect(() => {
        const outerClick = ({target}) => {
            if (isModalVisible && modalWrapperRef.current) {
                let shouldCloseModal = true;
                const modalWrapperRefDeepChildren = [...modalWrapperRef.current.querySelectorAll('*')];

                modalWrapperRefDeepChildren.forEach((element) => {
                    if (target === element) {
                        shouldCloseModal = false;
                    }
                });

                setIsModalVisible(!shouldCloseModal);
            }
        };

        if (!isMobile) {
            document.addEventListener('click', outerClick);
        }

        if (isModalVisible) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = 'unset';
            document.removeEventListener('click', outerClick);
        };
    }, [isModalVisible]);

    useLayoutEffect(() => {
        if (listWrapperRef.current && isModalVisible && isMobile) {
            const listItems = [...listWrapperRef.current.children];

            listItems.reduce((acc, current) => {
                if (acc.indexOf(current.dataset.lngId) > -1) {
                    current.remove();
                } else {
                    acc.push(current.dataset.lngId);
                }

                return acc;
            }, []);
        }
    }, [isModalVisible]);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const {isLanguageSelectionHidden} = Object.fromEntries(urlSearchParams.entries());

        setIsHidden(isLanguageSelectionHidden);
    }, []);

    if (!data || !filteredData || !isVisible || isHidden) return null;

    const selectedLanguage = i18n.language;
    const {flag, name} =
        data.find((el) => {
            return el.code === selectedLanguage;
        }) || {};

    const onOpen = () => {
        setIsModalVisible(!isModalVisible);
    };

    const onInputChange = (value) => {
        setFilterValue(value);
        setFilteredData(
            data.filter(({name}) =>
                translate(name)
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
    };

    const onLanguageSelect = async (code) => {
        await changeLanguage(code);
        setIsModalVisible(false);
    };

    const onWrapperClick = ({target}) => {
        if (target === modalWrapperRef.current) {
            setIsModalVisible(false);
        }
    };

    return (
        <>
            <div
                className={cn(styles.LanguageSelect, 'app-language-select', {[styles.IsForMobile]: isForMobile})}
                onClick={onOpen}
            >
                <img src={flag} alt={name} />
                <span>{t(name)}</span>
            </div>
            {isModalVisible && (
                <div
                    onClick={onWrapperClick}
                    ref={modalWrapperRef}
                    className={cn(styles.ModalWrapper, {[styles.Mobile]: isForMobile})}
                >
                    <div className={cn(styles.Modal, {[styles.Webview]: isWebview})}>
                        <div className={styles.SearchWrapper}>
                            <div className={styles.InputWrapper}>
                                <TextInput
                                    placeholder={t(localizationKeys.LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER)}
                                    className={styles.Input}
                                    onChange={onInputChange}
                                    value={filterValue}
                                />
                                <SVGSearchIcon />
                            </div>
                        </div>
                        <div className={styles.ListWrapper} ref={listWrapperRef}>
                            {filteredData.map(({flag, name, code}, index) => {
                                return (
                                    <div
                                        data-lng-id={name}
                                        className={styles.ListItem}
                                        key={index}
                                        onClick={() => onLanguageSelect(code)}
                                    >
                                        <img src={flag} alt={t(name)} />
                                        <span>{t(name)}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LanguageSelect;
