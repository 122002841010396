export default {
    CONFIRM: 'Confirm',
    OOPS_COMMA: 'Oops,',
    SOMETHING_WENT_WRONG_ACCENT: 'Something went wrong.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: "The page you're looking for no longer exists.",
    BACK_TO_HOME: 'Back to home',
    PICK_A_TIM: 'Pick a Time',
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    SELECT_TIME: 'Select Time',
    TEXT_24HR: '24 hr',
    SCHEDULE_CALL: 'Schedule Call',
    WHATSAPP: 'Whatsapp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Call',
    EMAIL: 'Email',
    WEBSITE: 'Website',
    OPENING_HOURS: 'Opening hours',
    BC_ABOUT_TITLE: 'About',
    BC_ADDRESS_TITLE: 'Address',
    BC_NAVIGATE_BTN_TEXT: 'Navigate',
    BC_WEBSITE_TITLE: 'Website',
    BC_CONTACT_US_TITLE: 'Contact Us',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Thank you!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Your message was sent successfully',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Invalid name',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'Your name',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Invalid number',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Phone number',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Write a message...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Send',
    BC_FOLLOW_US_TITLE: 'Follow us',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Share with friends',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Save to address book',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Create your own business card',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Schedule a Call',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'When to call back:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Confirm',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Pick a time',
    SCHEDULE_TIME_ASAP_TEXT: 'ASAP',
    SCHEDULE_TIME_30MIN_TEXT: 'in 30 min',
    SCHEDULE_TIME_1H_TEXT: 'in 1 hour',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Pick a Time',
    PICK_PAGE_SELECT_WHEN_POINT: 'Select When:',
    PICK_PAGE_TODAY_BTN_TEXT: 'Today',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Tomorrow',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Continue',
    TIME_LIST_IN_15_MIN: 'In 15 min',
    TIME_LIST_IN_30_MIN: 'In 30 min',
    TIME_LIST_IN_1_H: 'In 1 hour',
    TIME_LIST_AT_TIME: 'At {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'At {{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'Today',
    IDENT_PAGE_TOMORROW_TEXT: 'Tomorrow',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} at {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Invalid name',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Full name',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Invalid phone number',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Phone Number',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Thank You!',
    CONFIRMED_PAGE_DESCRIPTION: 'The call was successfully scheduled for {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'Have a lot of business calls?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Manage leads and win more customers with Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Try for Free',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Confirmed',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Continue',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Search language',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'Let’s talk. Please let me know when is the best time to reach you:',
    HERE_IS_MY_BC_LINK: 'Here is my business card link: {{link}}',
    OPENING_HOURS_MON: 'Mon',
    OPENING_HOURS_TUE: 'Tue',
    OPENING_HOURS_WED: 'Wed',
    OPENING_HOURS_THU: 'Thu',
    OPENING_HOURS_FRI: 'Fri',
    OPENING_HOURS_SAT: 'Sat',
    OPENING_HOURS_SUN: 'Sun',
};
