export default {
    CONFIRM: 'Conferma',
    OOPS_COMMA: 'Ops,',
    SOMETHING_WENT_WRONG_ACCENT: 'Qualcosa è andato storto.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'La pagina che stai cercando non esiste più.',
    BACK_TO_HOME: 'Torna alla home',
    PICK_A_TIM: 'Scegli un orario',
    TODAY: 'Oggi',
    TOMORROW: 'Domani',
    SELECT_TIME: 'Seleziona un orario',
    TEXT_24HR: '24 ore',
    SCHEDULE_CALL: 'Pianifica la chiamata',
    WHATSAPP: 'Whatsapp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Chiama',
    EMAIL: 'Email',
    WEBSITE: 'Sito web',
    OPENING_HOURS: 'Orari di apertura',
    BC_ABOUT_TITLE: 'Informazioni',
    BC_ADDRESS_TITLE: 'Indirizzo',
    BC_NAVIGATE_BTN_TEXT: 'Naviga',
    BC_WEBSITE_TITLE: 'Sito web',
    BC_CONTACT_US_TITLE: 'Contattaci',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Grazie!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Il tuo messaggio è stato inviato con successo',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Nome non valido',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'Il tuo nome',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Numero non valido',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Numero di telefono',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Scrivi un messaggio...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Invia',
    BC_FOLLOW_US_TITLE: 'Seguici',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Condividi con gli amici',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Salva nella rubrica',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Crea il tuo biglietto da visita',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Pianifica la chiamata',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Quando richiamare:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Conferma',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Scegli un orario',
    SCHEDULE_TIME_ASAP_TEXT: 'Il prima possibile',
    SCHEDULE_TIME_30MIN_TEXT: 'in 30 min',
    SCHEDULE_TIME_1H_TEXT: 'in 1 ora',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Scegli un orario',
    PICK_PAGE_SELECT_WHEN_POINT: 'Seleziona quando:',
    PICK_PAGE_TODAY_BTN_TEXT: 'Oggi',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Domani',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Continua',
    TIME_LIST_IN_15_MIN: 'In 15 minuti',
    TIME_LIST_IN_30_MIN: 'In 30 minuti',
    TIME_LIST_IN_1_H: 'In 1 ora',
    TIME_LIST_AT_TIME: 'Alle {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'Alle {{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'Oggi',
    IDENT_PAGE_TOMORROW_TEXT: 'Domani',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: 'Il {{day}} alle {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Nome non valido',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Nome completo',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Numero di telefono non valido',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Numero di telefono',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Grazie!',
    CONFIRMED_PAGE_DESCRIPTION: 'La chiamata è stata programmata per le {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'Hai tante chiamate di lavoro?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Gestisci i lead e aumenta i tuoi clienti con Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Provalo gratuitamente',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Confermato',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Continua',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Cerca paese',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'Dobbiamo parlare. Fammi sapere quando è il momento migliore per contattarti:',
    HERE_IS_MY_BC_LINK: 'Ecco il link del mio biglietto da visita: {{link}}',
    OPENING_HOURS_MON: 'Lun',
    OPENING_HOURS_TUE: 'Mar',
    OPENING_HOURS_WED: 'Mer',
    OPENING_HOURS_THU: 'Gio',
    OPENING_HOURS_FRI: 'Ven',
    OPENING_HOURS_SAT: 'Sab',
    OPENING_HOURS_SUN: 'Dom',
};
