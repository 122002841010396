export default {
    CONFIRM: 'אישור',
    OOPS_COMMA: 'אופס,',
    SOMETHING_WENT_WRONG_ACCENT: 'משהו השתבש.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'הדף שחיפשת אינו קיים עוד.',
    BACK_TO_HOME: 'בחזרה לעמוד הבית',
    PICK_A_TIM: 'בחירת זמן',
    TODAY: 'היום',
    TOMORROW: 'מחר',
    SELECT_TIME: 'בחירת שעה',
    TEXT_24HR: '24 שעות',
    SCHEDULE_CALL: 'קביעת שיחה',
    WHATSAPP: 'וואטסאפ',
    FACEBOOK: 'פייסבוק',
    TIKTOK: 'טיקטוק',
    INSTAGRAM: 'אינסטגרם',
    LINKEDIN: 'לינקדאין',
    TWITTER: 'טוויטר',
    CALL: 'חיוג',
    EMAIL: 'אימייל',
    WEBSITE: 'אתר אינטרנט',
    OPENING_HOURS: 'שעות פתיחה',
    BC_ABOUT_TITLE: 'אודות',
    BC_ADDRESS_TITLE: 'כתובת',
    BC_NAVIGATE_BTN_TEXT: 'ניווט',
    BC_WEBSITE_TITLE: 'אתר אינטרנט',
    BC_CONTACT_US_TITLE: 'צור קשר',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'תודה!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'ההודעה שלך נשלחה בהצלחה',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'שם לא תקין',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'השם שלך',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'מספר לא תקין',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'מספר טלפון',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'כתיבת הודעה...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'שלח',
    BC_FOLLOW_US_TITLE: 'עקבו אחרינו',
    BC_SHARE_WITH_FRIENDS_TITLE: 'שיתוף עם חברים',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'שמירה לאנשי קשר',
    FOOTER_CREATE_BC_PROMO_TEXT: 'יצירת כרטיס ביקור משלך',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'תזמון שיחה',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'מתי להתקשר בחזרה:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'אישור',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'בחירת זמן',
    SCHEDULE_TIME_ASAP_TEXT: 'בהקדם האפשרי',
    SCHEDULE_TIME_30MIN_TEXT: 'בעוד 30 דקות',
    SCHEDULE_TIME_1H_TEXT: 'בעוד שעה',
    PICK_PAGE_PICK_A_TIME_TITLE: 'בחירת זמן',
    PICK_PAGE_SELECT_WHEN_POINT: 'בחרו מתי:',
    PICK_PAGE_TODAY_BTN_TEXT: 'היום',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'מחר',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'המשך',
    TIME_LIST_IN_15_MIN: 'בעוד 15 דקות',
    TIME_LIST_IN_30_MIN: 'בעוד 30 דקות',
    TIME_LIST_IN_1_H: 'בעוד שעה',
    TIME_LIST_AT_TIME: 'בשעה 00:{{hours}}',
    TIME_LIST_AT_TIME_AMPM: 'בשעה 00:{{hours}} {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'היום',
    IDENT_PAGE_TOMORROW_TEXT: 'מחר',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} בשעה {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'שם לא תקין',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'שם מלא',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'מספר טלפון לא תקין',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'מספר טלפון',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'תודה!',
    CONFIRMED_PAGE_DESCRIPTION: 'השיחה תוזמנה בהצלחה ל{{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'יש לך הרבה שיחות עסקיות?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'ניהול לידים ולקוחות באמצעות לידר',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'נסו חינם',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'אישור',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'המשך',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'חיפוש שפה',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'בואו נדבר. אנא הודיעו לי מתי הזמן הטוב ביותר להשיג אתכם:',
    HERE_IS_MY_BC_LINK: 'הנה הקישור לכרטיס הביקור שלי: {{link}}',
    OPENING_HOURS_MON: 'שני',
    OPENING_HOURS_TUE: 'שלישי',
    OPENING_HOURS_WED: 'רביעי',
    OPENING_HOURS_THU: 'חמישי',
    OPENING_HOURS_FRI: 'שישי',
    OPENING_HOURS_SAT: 'שבת',
    OPENING_HOURS_SUN: 'ראשון',
};
