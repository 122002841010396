export default {
    CONFIRM: 'Bevestigen',
    OOPS_COMMA: 'Oeps,',
    SOMETHING_WENT_WRONG_ACCENT: 'Er ging iets mis.',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'De pagina die u zoekt bestaat niet meer.',
    BACK_TO_HOME: 'Terug naar home',
    PICK_A_TIM: 'Selecteer een tijd',
    TODAY: 'Vandaag',
    TOMORROW: 'Morgen',
    SELECT_TIME: 'Selecteer tijd',
    TEXT_24HR: '24 uur',
    SCHEDULE_CALL: 'Plan een gesprek',
    WHATSAPP: 'Whatsapp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'Bellen',
    EMAIL: 'E-mail',
    WEBSITE: 'Website',
    OPENING_HOURS: 'Openingsuren',
    BC_ABOUT_TITLE: 'Over',
    BC_ADDRESS_TITLE: 'Adres',
    BC_NAVIGATE_BTN_TEXT: 'Navigeren',
    BC_WEBSITE_TITLE: 'Website',
    BC_CONTACT_US_TITLE: 'Neem contact met ons op',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'Bedankt!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'Uw bericht is succesvol verzonden',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'Ongeldige naam',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'Uw naam',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'Ongeldig nummer',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'Telefoonnummer',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'Schrijf een bericht...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'Verstuur',
    BC_FOLLOW_US_TITLE: 'Volg ons',
    BC_SHARE_WITH_FRIENDS_TITLE: 'Delen met vrienden',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'Opslaan in adresboek',
    FOOTER_CREATE_BC_PROMO_TEXT: 'Maak uw eigen visitekaartje',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'Plan een gesprek',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'Wanneer terugbellen:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'Bevestigen',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'Selecteer een tijd',
    SCHEDULE_TIME_ASAP_TEXT: 'Z.S.M.',
    SCHEDULE_TIME_30MIN_TEXT: 'in 30 min',
    SCHEDULE_TIME_1H_TEXT: 'in 1 uur',
    PICK_PAGE_PICK_A_TIME_TITLE: 'Selecteer een tijd',
    PICK_PAGE_SELECT_WHEN_POINT: 'Selecteer wanneer:',
    PICK_PAGE_TODAY_BTN_TEXT: 'Vandaag',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'Morgen',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'Doorgaan',
    TIME_LIST_IN_15_MIN: 'In 15 min',
    TIME_LIST_IN_30_MIN: 'In 30 min',
    TIME_LIST_IN_1_H: 'In 1 uur',
    TIME_LIST_AT_TIME: 'Om {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'Om {{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'Vandaag',
    IDENT_PAGE_TOMORROW_TEXT: 'Morgen',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} om {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'Ongeldige naam',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'Volledige naam',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'Ongeldig telefoonnummer',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'Telefoonnummer',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'Bedankt!',
    CONFIRMED_PAGE_DESCRIPTION: 'Het gesprek is met succes gepland voor {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'Heeft u veel zakelijke gesprekken?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'Beheer leads en win meer klanten met Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'Probeer gratis',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'Bevestigd',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'Doorgaan',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'Land zoeken',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'Laten we praten. Laat me weten wanneer de beste tijd is om u te bereiken:',
    HERE_IS_MY_BC_LINK: 'Hier is de link naar mijn visitekaartje: {{link}}',
    OPENING_HOURS_MON: 'Ma',
    OPENING_HOURS_TUE: 'Di',
    OPENING_HOURS_WED: 'Wo',
    OPENING_HOURS_THU: 'Do',
    OPENING_HOURS_FRI: 'Vr',
    OPENING_HOURS_SAT: 'Za',
    OPENING_HOURS_SUN: 'Zo',
};
