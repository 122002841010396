import PoweredBy from 'components/UI/PoweredBy';
import React from 'react';
import {useTranslation} from 'react-i18next';

import localizationKeys from '../../consts/localization/localizationKeys';
import styles from './styles.module.scss';

const svgStyles = `.cls-1{opacity:.25}.cls-10,.cls-11,.cls-2,.cls-3,.cls-5,.cls-6,.cls-7,.cls-8{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.cls-2,.cls-7{stroke:#fc99c0}.cls-11,.cls-2,.cls-3,.cls-5{stroke-width:5px}.cls-10,.cls-3{stroke:#ff758f}.cls-4{opacity:.43}.cls-11,.cls-5,.cls-8{stroke:#656565}.cls-6{stroke:#fff}.cls-10,.cls-6,.cls-7,.cls-8{stroke-width:3px}.cls-9{opacity:.39}.cls-11{opacity:.48}`;

const Error404 = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.background}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='1277.737'
                    height='745.928'
                    viewBox='0 0 1277.737 745.928'
                >
                    <defs>
                        <style>{svgStyles}</style>
                    </defs>
                    <g id='flexs' className='cls-1' transform='translate(3.53 2.12)'>
                        <path
                            id='Path_1'
                            d='M185.645 17.646l-12.773-4.264L160.216 9l-1.758 13.384-1.758 13.384'
                            className='cls-2'
                            data-name='Path 1'
                            transform='translate(-76.264 301.347)'
                        />
                        <path
                            id='Path_2'
                            d='M109.109 301.736A12.305 12.305 0 1 1 96.8 289.3a12.369 12.369 0 0 1 12.309 12.436z'
                            className='cls-3'
                            data-name='Path 2'
                            transform='translate(857.484 310.535)'
                        />
                        <g id='Group_6' data-name='Group 6' transform='translate(410.01 620.498)'>
                            <path
                                id='Path_3'
                                d='M87.5 33.217l20.273-7.817 7.266 20.254'
                                className='cls-2'
                                data-name='Path 3'
                                transform='translate(-87.5 -25.4)'
                            />
                            <path
                                id='Path_4'
                                d='M111 43.717l20.273-7.817 7.266 20.372'
                                className='cls-2'
                                data-name='Path 4'
                                transform='translate(-83.461 -23.464)'
                            />
                        </g>
                        <g id='Group_7' className='cls-4' data-name='Group 7' transform='rotate(-90 832.37 -406.17)'>
                            <path id='Path_5' d='M0 7.734L20.609 0l7.225 20.156' className='cls-5' data-name='Path 5' />
                            <path
                                id='Path_6'
                                d='M0 7.734L20.49 0l7.343 20.039'
                                className='cls-5'
                                data-name='Path 6'
                                transform='translate(27.834 12.422)'
                            />
                        </g>
                        <path
                            id='Line_5'
                            d='M0 28.545L31.874 0'
                            className='cls-5'
                            data-name='Line 5'
                            transform='translate(1238.765 38.947)'
                        />
                        <path
                            id='Line_6'
                            d='M0 28.426L31.874 0'
                            className='cls-2'
                            data-name='Line 6'
                            transform='translate(0 670.907)'
                        />
                        <path
                            id='Path_7'
                            d='M284.423 9.9a4.4 4.4 0 0 1-5.859-2.014 4.487 4.487 0 0 1 1.992-5.922 4.414 4.414 0 0 1 3.867 7.936z'
                            className='cls-6'
                            data-name='Path 7'
                            transform='translate(534.073 96.093)'
                        />
                        <path
                            id='Path_10'
                            d='M135.723 270.5a4.4 4.4 0 0 1-5.859-2.013 4.487 4.487 0 0 1 1.992-5.922 4.414 4.414 0 0 1 3.867 7.936z'
                            className='cls-7'
                            data-name='Path 10'
                            transform='translate(828.145 369.432)'
                        />
                        <g id='Group_8' data-name='Group 8' transform='translate(1212.99 477.132)'>
                            <path
                                id='Line_7'
                                d='M0 0l7.851 7.462'
                                className='cls-8'
                                data-name='Line 7'
                                transform='translate(0 .237)'
                            />
                            <path
                                id='Line_8'
                                d='M7.383 0L0 7.936'
                                className='cls-8'
                                data-name='Line 8'
                                transform='translate(.234)'
                            />
                        </g>
                        <g id='Group_9' className='cls-9' data-name='Group 9' transform='translate(545.22 301.675)'>
                            <path
                                id='Line_9'
                                d='M0 0l7.851 7.462'
                                className='cls-7'
                                data-name='Line 9'
                                transform='translate(0 .237)'
                            />
                            <path
                                id='Line_10'
                                d='M7.383 0L0 7.936'
                                className='cls-7'
                                data-name='Line 10'
                                transform='translate(.234)'
                            />
                        </g>
                        <g id='Group_10' data-name='Group 10' transform='translate(844.559 171.746)'>
                            <path
                                id='Line_11'
                                d='M0 0l20.383 19.372'
                                className='cls-7'
                                data-name='Line 11'
                                transform='translate(0 .615)'
                            />
                            <path
                                id='Line_12'
                                d='M19.166 0L0 20.602'
                                className='cls-7'
                                data-name='Line 12'
                                transform='translate(.608)'
                            />
                        </g>
                        <g id='Group_11' data-name='Group 11' transform='translate(902.931 733.753)'>
                            <path
                                id='Line_13'
                                d='M0 0l7.734 7.462'
                                className='cls-6'
                                data-name='Line 13'
                                transform='translate(0 .237)'
                            />
                            <path
                                id='Line_14'
                                d='M7.265 0L0 7.936'
                                className='cls-6'
                                data-name='Line 14'
                                transform='translate(.234)'
                            />
                        </g>
                        <g id='Group_12' data-name='Group 12' transform='translate(251.945 552.711)'>
                            <path
                                id='Line_15'
                                d='M0 0l7.851 7.462'
                                className='cls-10'
                                data-name='Line 15'
                                transform='translate(0 .237)'
                            />
                            <path
                                id='Line_16'
                                d='M7.383 0L0 7.936'
                                className='cls-10'
                                data-name='Line 16'
                                transform='translate(.234)'
                            />
                        </g>
                        <g id='Group_13' data-name='Group 13' transform='translate(109.581)'>
                            <path
                                id='Line_17'
                                d='M0 0l7.851 7.462'
                                className='cls-10'
                                data-name='Line 17'
                                transform='translate(0 .237)'
                            />
                            <path
                                id='Line_18'
                                d='M7.383 0L0 7.936'
                                className='cls-10'
                                data-name='Line 18'
                                transform='translate(.234)'
                            />
                        </g>
                        <g id='Group_14' data-name='Group 14' transform='translate(784.247 123.645)'>
                            <path
                                id='Line_19'
                                d='M0 0l7.851 7.343'
                                className='cls-8'
                                data-name='Line 19'
                                transform='translate(0 .237)'
                            />
                            <path
                                id='Line_20'
                                d='M7.383 0L0 7.817'
                                className='cls-8'
                                data-name='Line 20'
                                transform='translate(.234)'
                            />
                        </g>
                        <g id='Group_15' data-name='Group 15' transform='translate(539.309 301.892)'>
                            <path
                                id='Line_21'
                                d='M0 0l7.851 7.462'
                                className='cls-10'
                                data-name='Line 21'
                                transform='translate(0 .237)'
                            />
                            <path
                                id='Line_22'
                                d='M7.383 0L0 7.936'
                                className='cls-10'
                                data-name='Line 22'
                                transform='translate(.234)'
                            />
                        </g>
                        <path
                            id='Path_12'
                            d='M371.809 426.236A12.305 12.305 0 1 1 359.5 413.8a12.523 12.523 0 0 1 12.309 12.436z'
                            className='cls-11'
                            data-name='Path 12'
                            transform='translate(78.056 -278.436)'
                        />
                    </g>
                </svg>
            </div>
            <div className={styles.backgroundText}>404</div>
            <div className={styles.watermark}>
                <PoweredBy />
            </div>
            <h3 className={styles.header}>{t(localizationKeys.OOPS_COMMA)}</h3>
            <h5 className={styles.subheader}>{t(localizationKeys.SOMETHING_WENT_WRONG_ACCENT)}</h5>
            <p className={styles.description}>{t(localizationKeys.PAGE_YOU_LOOK_NO_LONGER_EXISTS)}</p>
            <a href='https://leader.net' className={styles.link}>
                {t(localizationKeys.BACK_TO_HOME)}
            </a>
        </div>
    );
};

export default Error404;
