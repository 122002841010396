import getBrowserHistory from '../browser/history';

const redirect = (url, isBlankOpen) => window.open(url, isBlankOpen ? '_blank' : '');
const redirectToScheduleCall = (cardId) => redirect(`/${cardId}/bc`, true);
const getIdentPageUrl = (callbackId, isBC) => (isBC ? `/${callbackId}/bc/ident` : `/assistant/${callbackId}/ident`);
const getPickPageUrl = (callbackId, isBC) => (isBC ? `/${callbackId}/bc/pick` : `/assistant/${callbackId}/pick`);
const getConfirmedPageUrl = (callbackId, isBC) =>
    isBC ? `/${callbackId}/bc/confirm` : `/assistant/${callbackId}/confirm`;
const back = () => getBrowserHistory().back();

export default {
    redirect,
    redirectToScheduleCall,
    getIdentPageUrl,
    getPickPageUrl,
    back,
    getConfirmedPageUrl,
};
