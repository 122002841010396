import './App.scss';

import cn from 'classnames';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import useWebviewCheck from '../../hooks/useWebviewCheck';
import DataLayerProvider, {DataLayerContext} from '../../providers/DataLayer';
import LayoutProvider, {LayoutContext} from '../../providers/Layout';
import {makeSelectIsRTLLanguageSelected, makeSelectIsWebview} from '../../store/selectors/global';
import Router from '../Router/Router';
import {initGA} from '../Tracking/Tracking';

const App = () => {
    const isRTL = useSelector(makeSelectIsRTLLanguageSelected());
    const isWebview = useSelector(makeSelectIsWebview());

    useWebviewCheck();

    useEffect(() => {
        initGA();
    }, []);

    return (
        <div className={cn({['app-rtl']: isRTL, ['app-android-webview']: isWebview})}>
            <LayoutProvider>
                <LayoutContext.Consumer>
                    {(layout) => (
                        <DataLayerProvider>
                            <DataLayerContext.Consumer>
                                {(data) => <Router layout={layout} data={data} />}
                            </DataLayerContext.Consumer>
                        </DataLayerProvider>
                    )}
                </LayoutContext.Consumer>
            </LayoutProvider>
        </div>
    );
};

export default App;
