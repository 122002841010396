export default {
    CONFIRM: '確定',
    OOPS_COMMA: '哎呀！',
    SOMETHING_WENT_WRONG_ACCENT: '出錯了',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: '頁面已不見了',
    BACK_TO_HOME: '返回首页',
    PICK_A_TIM: '選擇時間',
    TODAY: '今天',
    TOMORROW: '明天',
    SELECT_TIME: '選擇時間',
    TEXT_24HR: '24 小時',
    SCHEDULE_CALL: '預約電話',
    WHATSAPP: 'WhatsApp',
    FACEBOOK: 'Facebook',
    TIKTOK: '抖音',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: '通話',
    EMAIL: '郵箱',
    WEBSITE: '網站',
    OPENING_HOURS: '工作時間',
    BC_ABOUT_TITLE: '關於',
    BC_ADDRESS_TITLE: '地址',
    BC_NAVIGATE_BTN_TEXT: '導航',
    BC_WEBSITE_TITLE: '網站',
    BC_CONTACT_US_TITLE: '聯絡我們',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: '謝謝！',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: '成功發出',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: '名稱無效',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: '您的全名',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: '無效的電話號碼',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: '電話號碼',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: '編寫短訊息...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: '發出',
    BC_FOLLOW_US_TITLE: '关注我们',
    BC_SHARE_WITH_FRIENDS_TITLE: '推薦給朋友',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: '保存到位址簿',
    FOOTER_CREATE_BC_PROMO_TEXT: '創建名片',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: '預約電話',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: '回電時間：',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: '確定',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: '選擇時間',
    SCHEDULE_TIME_ASAP_TEXT: '盡快',
    SCHEDULE_TIME_30MIN_TEXT: '30分鐘內',
    SCHEDULE_TIME_1H_TEXT: '1小時內',
    PICK_PAGE_PICK_A_TIME_TITLE: '選擇時間',
    PICK_PAGE_SELECT_WHEN_POINT: '選擇時間：',
    PICK_PAGE_TODAY_BTN_TEXT: '今天',
    PICK_PAGE_TOMORROW_BTN_TEXT: '明天',
    PICK_PAGE_CONTINUE_BTN_TEXT: '繼續',
    TIME_LIST_IN_15_MIN: '15分鐘內',
    TIME_LIST_IN_30_MIN: '30分鐘內',
    TIME_LIST_IN_1_H: '1小時內',
    TIME_LIST_AT_TIME: '時間{{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: '時間：{{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: '今天',
    IDENT_PAGE_TOMORROW_TEXT: '明天',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} 時間:{{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: '名稱無效',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: '全名',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: '無效的電話號碼',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: '電話號碼',
    CONFIRMED_PAGE_CONFIRMED_TEXT: '謝謝！',
    CONFIRMED_PAGE_DESCRIPTION: '成功預約電話，時間：{{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: '業務電話太多？',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: '透過Leader管理潛在客戶和贏得更多客戶',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: '免費試用',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: '確定',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: '繼續',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: '搜索國家',
    SCHEDULE_PAGE_SUBTITLE_TEXT: '我們約個時間談談。請告知我一個可以聯絡您的合適時間。',
    HERE_IS_MY_BC_LINK: '這是我的名片鏈接：{{link}}',
    OPENING_HOURS_MON: '週一',
    OPENING_HOURS_TUE: '週二',
    OPENING_HOURS_WED: '週三',
    OPENING_HOURS_THU: '週四',
    OPENING_HOURS_FRI: '週五',
    OPENING_HOURS_SAT: '週六',
    OPENING_HOURS_SUN: '週日',
};
