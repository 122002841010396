import Axios from 'axios';

import apiHeaders from '../../consts/api/apiHeaders';
import {
    getGetBusinessCardEndpoint,
    getGetVCardEndpoint,
    getPutMessageEndpoint,
    getUserInfoEndpoint,
} from './endpointService';

const axios = Axios.create({
    headers: {
        [apiHeaders.X_SYNCAI_APP_ID]: process.env.REACT_APP_SYNCAI_APP_ID,
        [apiHeaders.X_SYNCAI_APP_VERSION]: process.env.REACT_APP_SYNCAI_APP_VERSION,
        [apiHeaders.X_SYNCAI_APP_UUID]: process.env.REACT_APP_SYNCAI_APP_UUID,
    },
});

export default {
    setUserInfo: ({callback_id, isBusinessCard, body}) =>
        axios.put(getUserInfoEndpoint(callback_id, isBusinessCard), body),
    getUserInfo: ({callback_id, isBusinessCard}) => axios.get(getUserInfoEndpoint(callback_id, isBusinessCard)),
    getBusinessCard: ({callback_id}) => axios.get(getGetBusinessCardEndpoint(callback_id)),
    putMessage: ({slug, body}) => axios.put(getPutMessageEndpoint(slug), body),
    getVCard: (slug) => axios.get(getGetVCardEndpoint(slug)),
};
