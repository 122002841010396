import config from './config';

export default {
    getLeaderAndroidLink: () => config.links.leaderAndroidLink,
    getLeaderIOSLink: () => config.links.leaderIOSLink,
    getLeaderDesktopLink: () => config.links.leaderDesktopLink,
    getGATrackingCode: () => config.ga.trackingCode,
    getLeaderFacebookLink: () => config.links.leaderFacebookLink,
    getLeaderTwitterLink: () => config.links.leaderTwitterLink,
    getLeaderLinkedinLink: () => config.links.leaderLinkedinLink,
    getLeaderYoutubeLink: () => config.links.leaderYoutubeLink,
    getLeaderInstagramLink: () => config.links.leaderInstagramLink,
    getWazeLiveMspLink: () => config.thirdAppsLinks.wazeLiveMap,
    getGoogleMapLink: () => config.thirdAppsLinks.googleMapLink,
    getUberLoginLink: () => config.thirdAppsLinks.uberLoginLink,
    getFacebookLink: () => config.thirdAppsLinks.facebookLink,
    getTwitterLink: () => config.thirdAppsLinks.twitterLink,
};
