export default {
    CONFIRM: 'ยืนยัน',
    OOPS_COMMA: 'อ๊ะ',
    SOMETHING_WENT_WRONG_ACCENT: 'มีบางอย่างผิดพลาด',
    PAGE_YOU_LOOK_NO_LONGER_EXISTS: 'หน้าที่คุณกําลังค้นหาไม่มีอยู่อีกต่อไป',
    BACK_TO_HOME: 'กลับสู่หน้าหลัก',
    PICK_A_TIM: 'เลือกเวลา',
    TODAY: 'วันนี้',
    TOMORROW: 'พรุ่งนี้',
    SELECT_TIME: 'เลือกเวลา',
    TEXT_24HR: '24 ชม.',
    SCHEDULE_CALL: 'กําหนดเวลาการโทร',
    WHATSAPP: 'Whatsapp',
    FACEBOOK: 'Facebook',
    TIKTOK: 'Tiktok',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    CALL: 'โทร',
    EMAIL: 'อีเมล',
    WEBSITE: 'เว็บไซต์',
    OPENING_HOURS: 'เวลาเปิดทําการ',
    BC_ABOUT_TITLE: 'เกี่ยวกับ',
    BC_ADDRESS_TITLE: 'ที่อยู่',
    BC_NAVIGATE_BTN_TEXT: 'นำทาง',
    BC_WEBSITE_TITLE: 'เว็บไซต์',
    BC_CONTACT_US_TITLE: 'ติดต่อเรา',
    BC_CONTACT_US_MESSAGE_SENT_TITLE: 'ขอบคุณ!',
    BC_CONTACT_US_MESSAGE_SENT_DESCRIPTION: 'ข้อความของคุณถูกส่งเรียบร้อยแล้ว',
    BC_CONTACT_US_INPUT_NAME_ERROR_MESSAGE: 'ชื่อไม่ถูกต้อง',
    BC_CONTACT_US_INPUT_NAME_PLACEHOLDER: 'ชื่อของคุณ',
    BC_CONTACT_US_INPUT_TEL_ERROR_MESSAGE: 'หมายเลขไม่ถูกต้อง',
    BC_CONTACT_US_INPUT_TEL_PLACEHOLDER: 'หมายเลขโทรศัพท์',
    BC_CONTACT_US_COMMENT_PLACEHOLDER: 'เขียนข้อความ...',
    BC_CONTACT_US_BUTTON_SEND_TEXT: 'ส่ง',
    BC_FOLLOW_US_TITLE: 'ติดตามเรา',
    BC_SHARE_WITH_FRIENDS_TITLE: 'แชร์กับเพื่อน ๆ',
    BC_SAVE_TO_ADDRESS_BOOK_TITLE: 'บันทึกไปยังสมุดรายชื่อ',
    FOOTER_CREATE_BC_PROMO_TEXT: 'สร้างนามบัตรของคุณเอง',
    CARD_HEADER_SCHEDULE_A_CALL_BTN_TEXT: 'กําหนดเวลาการโทร',
    SCHEDULE_CALL_WHEN_CALL_BACK_TITLE: 'เวลาที่ควรโทรกลับ:',
    SCHEDULE_CALL_CONFIRM_BUTTON_TEXT: 'ยืนยัน',
    SCHEDULE_CALL_PICK_BUTTON_TEXT: 'เลือกเวลา',
    SCHEDULE_TIME_ASAP_TEXT: 'โดยเร็วที่สุด',
    SCHEDULE_TIME_30MIN_TEXT: 'ใน 30 นาที',
    SCHEDULE_TIME_1H_TEXT: 'ใน 1 ชั่วโมง',
    PICK_PAGE_PICK_A_TIME_TITLE: 'เลือกเวลา',
    PICK_PAGE_SELECT_WHEN_POINT: 'เลือกเวลา:',
    PICK_PAGE_TODAY_BTN_TEXT: 'วันนี้',
    PICK_PAGE_TOMORROW_BTN_TEXT: 'พรุ่งนี้',
    PICK_PAGE_CONTINUE_BTN_TEXT: 'ดำเนินการต่อ',
    TIME_LIST_IN_15_MIN: 'ใน 15 นาที',
    TIME_LIST_IN_30_MIN: 'ใน 30 นาที',
    TIME_LIST_IN_1_H: 'ใน 1 ชั่วโมง',
    TIME_LIST_AT_TIME: 'เวลา {{hours}}:00',
    TIME_LIST_AT_TIME_AMPM: 'เวลา {{hours}}:00 {{amPm}}',
    IDENT_PAGE_TODAY_TEXT: 'วันนี้',
    IDENT_PAGE_TOMORROW_TEXT: 'พรุ่งนี้',
    IDENT_PAGE_SELECTED_TIME_CARD_TEXT: '{{day}} เวลา {{time}}',
    IDENT_PAGE_NAME_INPUT_ERROR_MESSAGE: 'ชื่อ-นามสกุลไม่ถูกต้อง',
    IDENT_PAGE_NAME_INPUT_LABEL_TEXT: 'ชื่อ-นามสกุล',
    IDENT_PAGE_NUMBER_ERROR_MESSAGE: 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
    IDENT_PAGE_NUMBER_INPUT_LABEL_TEXT: 'หมายเลขโทรศัพท์',
    CONFIRMED_PAGE_CONFIRMED_TEXT: 'ขอบคุณ!',
    CONFIRMED_PAGE_DESCRIPTION: 'กำหนดเวลาการโทรสำเร็จสำหรับ {{selectedTime}}',
    CONFIRMED_PAGE_FOOTER_TITLE: 'มีการโทรติดต่อทางธุรกิจเป็นจำนวนมากใช่ไหม?',
    CONFIRMED_PAGE_FOOTER_DESCRIPTION: 'จัดการลูกค้าเป้าหมายและชนะใจลูกค้ามากขึ้นด้วย Leader',
    CONFIRMED_PAGE_FOOTER_BTN_TEXT: 'ทดลองใช้ฟรี',
    CONFIRMED_PAGE_CONFIRM_LOGO_ALT_TEXT: 'ยืนยันแล้ว',
    IDENT_PAGE_SCHEDULE_BTN_TEXT: 'ดำเนินการต่อ',
    LANGUAGE_SELECT_MODAL_INPUT_PLACEHOLDER: 'ค้นหาภาษา',
    SCHEDULE_PAGE_SUBTITLE_TEXT: 'มาคุยกันเถอะ โปรดแจ้งให้เราทราบถึงเวลาที่คุณสะดวกให้เราติดต่อไปหาคุณ:',
    HERE_IS_MY_BC_LINK: 'นี่คือลิงก์นามบัตรของฉัน: {{link}}',
    OPENING_HOURS_MON: 'จ',
    OPENING_HOURS_TUE: 'อ',
    OPENING_HOURS_WED: 'พ',
    OPENING_HOURS_THU: 'พฤ',
    OPENING_HOURS_FRI: 'ศ',
    OPENING_HOURS_SAT: 'ส',
    OPENING_HOURS_SUN: 'อา',
};
