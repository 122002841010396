import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import languageCode from '../../consts/localization/languageCode';
import {setIsRTLLanguageSelected} from '../../store/reducers/global';
import {dispatch} from '../../store/store';
import strings_ar from './locale/ar.js';
import strings_de from './locale/de.js';
import strings_en from './locale/en.js';
import strings_es from './locale/es.js';
import strings_fa from './locale/fa.js';
import strings_fr from './locale/fr.js';
import strings_he from './locale/he.js';
import strings_hi from './locale/hi.js';
import strings_it from './locale/it.js';
import strings_ja from './locale/ja.js';
import strings_ko from './locale/ko.js';
import strings_nl from './locale/nl.js';
import strings_pt from './locale/pt.js';
import strings_pt_br from './locale/pt_BR.js';
import strings_ru from './locale/ru.js';
import strings_th from './locale/th.js';
import strings_tr from './locale/tr.js';
import strings_uk from './locale/uk.js';
import strings_zh_cn from './locale/zh_CN.js';
import strings_zh_tw from './locale/zh_TW.js';
import {addResourceBundle, isRTLLanguage} from './localeService';

i18n.on('initialized', () => {
    addResourceBundle(languageCode.AR, strings_ar);
    addResourceBundle(languageCode.EN, strings_en);
    addResourceBundle(languageCode.DE, strings_de);
    addResourceBundle(languageCode.ES, strings_es);
    addResourceBundle(languageCode.FA, strings_fa);
    addResourceBundle(languageCode.FR, strings_fr);
    addResourceBundle(languageCode.HE, strings_he);
    addResourceBundle(languageCode.HI, strings_hi);
    addResourceBundle(languageCode.IT, strings_it);
    addResourceBundle(languageCode.JA, strings_ja);
    addResourceBundle(languageCode.KO, strings_ko);
    addResourceBundle(languageCode.NL, strings_nl);
    addResourceBundle(languageCode.PT, strings_pt);
    addResourceBundle(languageCode.PT_BR, strings_pt_br);
    addResourceBundle(languageCode.RU, strings_ru);
    addResourceBundle(languageCode.TH, strings_th);
    addResourceBundle(languageCode.TR, strings_tr);
    addResourceBundle(languageCode.UK, strings_uk);
    addResourceBundle(languageCode.ZH_TW, strings_zh_tw);
    addResourceBundle(languageCode.ZH_CN, strings_zh_cn);
});

i18n.on('languageChanged', (lng) => {
    dispatch(setIsRTLLanguageSelected(isRTLLanguage(lng)));
});

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    keySeparator: false,
    attributes: [],
    interpolation: {
        escapeValue: false,
    },
    react: {
        bindI18n: 'languageChanged',
    },
});

export default i18n;
