export default {
    BC_CARD_PAGE: '/:cardID',
    BC_CONFIRM_PAGE: '/:callbackID/bc/confirm',
    BC_IDENT_PAGE: '/:callbackID/bc/ident',
    BC_PICK_TIME_PAGE: '/:callbackID/bc/pick',
    BC_MAIN_PAGE: '/:callbackID/bc',
    ASSISTANT_MAIN_PAGE: '/assistant/:callbackID',
    ASSISTANT_PICK_PAGE: '/assistant/:callbackID/pick',
    ASSISTANT_IDENT_PAGE: '/assistant/:callbackID/ident',
    ASSISTANT_CONFIRM_PAGE: '/assistant/:callbackID/confirm',
    ERROR_404: '/error/404',
};
